import React, { useState } from "react";
import Slider from "react-slick";
import Lightbox from "react-image-lightbox";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-image-lightbox/style.css";
import "../../assets/styles/slider.css";

import default_image_url from "../../assets/images/default_product.jpeg";

export default function DetailSlider({ data }) {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const image_url = `https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/`;
  const img_default = default_image_url;
  const imgDetailAlt = data?.product_name;

  const sliderSettingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    fade: true,
    asNavFor: nav2,
    ref: (slider) => setNav1(slider),
    beforeChange: (_, next) => setPhotoIndex(next),
  };

  const sliderSettingsThumbs = {
    slidesToShow: Math.min(data?.images?.length || 1, 5),
    slidesToScroll: 1,
    infinite: true,
    focusOnSelect: true,
    swipeToSlide: true,
    asNavFor: nav1,
    ref: (slider) => setNav2(slider),
  };

  const images = data?.images?.map(
    (item) => image_url + item?.directory + "/" + item?.file_name
  ) || [img_default];

  return (
    <div className="detailSlider autodetail_sect">

      {/* Main Slider */}
      <div className="mainSlider_block">
        <div className="slide-info">
          <span>
            {photoIndex + 1}/{images.length}
          </span>
        </div>
        <Slider {...sliderSettingsMain} className="mainSlider">
          {images.map((src, index) => (
            <div key={index} onClick={() => setIsOpen(true)}>
              <img
                src={src}
                alt={imgDetailAlt}
                className="normal-img"
                style={{ cursor: "pointer" }}
              />
            </div>
          ))}
        </Slider>
      </div>

      {/* Thumbnails Slider */}
      <Slider {...sliderSettingsThumbs} className="smallImages">
        {images.map((src, index) => (
          <div key={index} className="smallImg">
            <img src={src} alt={imgDetailAlt} />
          </div>
        ))}
      </Slider>

      {/* Lightbox */}
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
}
