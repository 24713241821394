// import * as yup from "yup";

// export const schema = yup.object().shape({
//     // product_code:yup.string(),
//     type: yup.string().required(),
//     make: yup.string().required(),
//     model: yup.string().required(),
//     vin_code: yup.string().required(),
//     product_name:yup.string().required(),
//     year:yup.string().required(),
//     description:yup.string().required(),
//     fullname:yup.string().required(),
//     email:yup.string().required(),
//     phone: yup.string()
//     .required()
//     .matches(/^\(\d{3}\) \d{3}-\d{2}-\d{2}$/, "10 rəqəm olmalı")
//     .min(10, '10 simvol olmalı')
//     .max(16, ' 10 simvol olmalı'),
// });

import * as yup from "yup";

export const schema = yup.object().shape({
    type: yup
        .string()
        .required("Növ daxil edilməlidir"),
    make: yup
        .string()
        .required("Marka daxil edilməlidir"),
    model: yup
        .string()
        .required("Model daxil edilməlidir"),
    vin_code: yup
        .string()
        .required("VIN kod daxil edilməlidir")
        .length(17, "VIN kod 17 simvoldan ibarət olmalıdır"),
    product_name: yup
        .string()
        .required("Məhsul adı daxil edilməlidir"),
    year: yup
        .string()
        .required("İl daxil edilməlidir")
        .matches(/^\d{4}$/, "İl dörd rəqəmdən ibarət olmalıdır"),
    description: yup
        .string()
        .required("Təsvir daxil edilməlidir")
        .min(10, "Təsvir ən az 10 simvol olmalıdır"),
    fullname: yup
        .string()
        .required("Tam ad daxil edilməlidir")
        .min(3, "Tam ad ən az 3 simvol olmalıdır"),
    email: yup
        .string()
        .required("E-poçt ünvanı daxil edilməlidir")
        .email("E-poçt formatı düzgün deyil"),
    phone: yup
        .string()
        .required("Telefon nömrəsi daxil edilməlidir")
        .matches(/^\(\d{3}\) \d{3}-\d{2}-\d{2}$/, "Format: (XXX) XXX-XX-XX")
        .min(10, "Telefon nömrəsi ən az 10 simvol olmalıdır")
        .max(16, "Telefon nömrəsi ən çox 16 simvol olmalıdır"),
});
