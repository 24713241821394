import React, { memo, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import classes from "./card.module.css";
import { FaHeart } from "react-icons/fa";
import { S3_LINK } from "../../../helpers/contants/s3";
import { EyeCardIcon, LocationCard, Manat } from "../../../assets/icons/Icons";
import slugify from "react-slugify";
import { AuthContext } from "../../../context/AuthContext";
import {
  deleteWishlistsItem,
  postWishLists,
} from "../../../services/wishListService";

import default_image_url from "../../../assets/images/default_product.jpeg";

const Card = (props) => {
  const navigate = useNavigate();
  const {
    isAuthenticated,
    wishListItem,
    setWishListItem,
    getAllWishLists,
    setWishLists,
    wishLists,
  } = useContext(AuthContext);
  const { card } = props;
  const maxLength = props.imgHeight ? 18 : 22;
  const img_url = S3_LINK + card?.cover;
  const img_default = default_image_url;

  const urlObject = new URL(img_url);

  // Extract the path
  const pathDefault = urlObject.pathname;

  // Assuming path is "/uploads.avtopro.general/null"
  const img_url2 = pathDefault.split("/");

  // Extract the last part (in this case, "/null")
  const lastPart = img_url2[img_url2.length - 1];

  let updated_img_url = img_url; // Yeni bir değişken kullanın, çünkü img_url const olarak tanımlandı.

  if (lastPart == undefined || lastPart === "null" || lastPart === "") {
    updated_img_url = img_default;
  }

  const productName =
    card?.product_name?.length > maxLength
      ? card?.product_name.slice(0, maxLength) + "..."
      : card?.product_name;
  const productAlt = card?.product_name;

  const isItemInWishList2 = wishListItem?.some((item) => item === card?.id);

  const [heart2, setHeart2] = useState(isItemInWishList2);

  const modifiedText = card?.product_name?.replace(/ə/g, "e");
  const slug = slugify(modifiedText, {
    lower: true,
    strict: true,
    replacement: "-",
  });

  const handleAddToWishList2 = async (card) => {
    const data = {
      product_id: card?.id,
    };
    const isItemInWishList = wishListItem?.some((item) => item === card?.id);
    if (!isItemInWishList) {
      setHeart2(!heart2);
      setWishListItem([...wishListItem, card?.id]);
      setWishLists([...wishLists, card]);
      if (!isAuthenticated) {
        navigate("/register");
      } else {
        try {
          await postWishLists(data);
        } catch (error) {
        } finally {
          getAllWishLists();
        }
      }
    }
  };

  const handleRemoveFromWishList2 = async (card) => {
    const updatedWishItems = wishListItem.filter(
      (wishItem) => wishItem !== card.id
    );
    if (updatedWishItems) {
      setHeart2(!heart2);
      setWishListItem(updatedWishItems);
      try {
        await deleteWishlistsItem(card?.id);
      } catch (error) {
        console.log(error);
      } finally {
        getAllWishLists();
      }
    }
  };

  const productSchema = {
    "@context": "https://schema.org",
    "@type": "Product",
    description: `${productName} - məhsulun satışı avtopro.az'da`,
    name: `${productName}`,
    image: `${updated_img_url}`,
    offers: {
      "@type": "Offer",
      availability: "1",
      price: `${card?.price}`,
      priceCurrency: "AZN",
    },
    // "seller": {
    //     "@type": "Organization",
    //     "name": "magaza adi",
    //     "url": "magazanin avtoprodaki linki",
    //     "logo": "magaza logosu",
    //     "description": "magaza descriptionu yoxdursa adi"
    // }
  };

  return (
    <article className={`${classes.card} slider__card`}>
      {card?.shop_type === 2 && (
        <button className="btn__card btn__card__primary">Mağaza</button>
      )}
      {card?.shop_type === 1 && (
        <button className="btn__card btn__card__black">Rəsmi diler</button>
      )}
      {card?.shop_type === 3 && (
        <button className="btn__card btn__card__black">Avtosalon</button>
      )}
      {card?.shop_id === 0 && (
        <button className="btn__card btn__card__black">Fərdi</button>
      )}
      <Link
        to={`/products/${slug}-${card.id}`}
        className={classes.card__image}
        target="_blank"
      >
        <img
          src={updated_img_url}
          style={{ height: `${props.imgHeight}rem` }}
          alt={productAlt}
          loading="lazy"
        />
      </Link>

      <div className={classes.card__body}>
        <div className={classes.d_flex}>
          <p className={classes.card_prices}>
            {!(card?.price === "0.00" || card?.price === null) && (
              <strong>
                {card?.price} <span className={classes.manat}>{Manat}</span>
              </strong>
            )}
            {!(card?.old_price === "0.00" || card?.old_price === null) && (
              <strong className={classes.discount}>
                {card?.old_price} <span className={classes.manat}>{Manat}</span>{" "}
              </strong>
            )}
          </p>

          <span>
            <FaHeart
              color={heart2 ? "#D4252A" : "#ccc"}
              onClick={() =>
                heart2
                  ? handleRemoveFromWishList2(card)
                  : handleAddToWishList2(card)
              }
            />
          </span>
        </div>
        <Link
          to={`/products/${slug}-${card.id}`}
          className={classes.card__image}
          target="_blank"
        >
          <p className={classes.card__text}>{productName}</p>
        </Link>
        <Link
          to={`/products/${slug}-${card.id}`}
          className={classes}
          target="_blank"
        >
          <div className={classes.card__bottom}>
            <p>
              {/* {card?.city_name !== null && LocationCard}   */}
              {card?.city_name ?? ""} |{" "}
            </p>
            <p>
              {((card?.created_at ?? "").split(" ")[0] ?? "").replace("-", ".")}{" "}
            </p>
            <p className={classes.card_view}>
              {EyeCardIcon} {card?.views ?? 0}
            </p>
          </div>
        </Link>
      </div>

      <script type="application/ld+json">
        {JSON.stringify(productSchema)}
      </script>
    </article>
  );
};

export default memo(Card);
