// import React from 'react'
// import classes from "./cart-item.module.css"
// import { Link } from 'react-router-dom'
// import { deleteBasketItem, updateBasketItem } from '../../../services/basketService'
// import { ToastContainer, toast } from 'react-toastify';
// import { useContext } from 'react';
// import { AuthContext } from '../../../context/AuthContext';

// const CartItem = (props) => {
//   const { basketListsCount, setBasketListsCount } = useContext(AuthContext)
//   const { data, item, setBasketLists, handleCheckboxChange, setSelectedItems, selectedItems } = props

//   const img_url = "https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/"

//   // console.log(item);
//   // const productNumber2 = item?.product?.product_numbers[0]?.part_number
//   const productNumber = item?.product?.product_numbers?.map((item) => item?.part_number)
//   const carMakes = item?.product?.car_makes.map((item) => item.name);
//   // const category = item?.product?.category?.category_name;
//   const uniqeCarMakes = [...new Set(carMakes)]

//   const removeCartItem = async (id) => {

//     const newItem = data?.filter((item) => item.id !== id)
//     const removeSelected = selectedItems?.filter((item) => item.id !== id)
//     const removeBasket = basketListsCount?.filter((item) => item.id !== id)
//     setBasketLists(newItem)
//     setSelectedItems(removeSelected)
//     setBasketListsCount(removeBasket)
//     toast.success('Məhsul silindi');
//     try {
//       await deleteBasketItem(id)
//     } catch(error) {
//       console.log(error)
//     }

//   }

//   const updateCartItem = async (cart, get) => {

//     const newItem = data?.find((item) => item.id === cart.id);

//     let countItem
//     if(get) {
//       countItem = newItem.count + 1
//     } else {
//       countItem = newItem.count - 1
//       if(countItem < 1) {
//         countItem = 1
//       }
//     }

//     if(newItem) {
//       const updatedItem = {
//         ...newItem,
//         count: countItem,
//       };

//       setBasketLists((prevLists) => {
//         const updatedLists = prevLists.map((item) => {
//           if(item.id === updatedItem.id) {
//             return updatedItem;
//           }
//           return item;
//         });
//         return updatedLists;
//       });

//       setSelectedItems((prevItems) =>
//         prevItems.map((item) =>
//           item.id === cart.id ? { ...item, count: countItem } : item
//         )
//       );

//       try {
//         await updateBasketItem(updatedItem);

//       } catch(error) {
//         console.log(error);
//       }
//     }
//   };

//   return (
//     <>
//       <div className={`${classes.cartItem}`}>
//         <input type="checkbox" className={classes.check} onChange={(e) => handleCheckboxChange(e, item)} />
//         <p className={`${classes.image}`}><img src={img_url + item?.product?.cover} alt={item?.product?.product_name} /></p>
//         <div className={classes.item__2}>
//           <h5>{item?.product?.product_name}</h5>
//           <p className={classes.carLists}>{uniqeCarMakes.map((car, i) => <span key={i}>{car}</span>)}</p>
//           {/* <p className={classes.carShop}><span>{item?.product?.shop?.name}</span></p> */}
//         </div>
//         <p className={classes.item__3}>{productNumber.length ? `OEM kod: ${productNumber}` : ''}</p>
//         {/* <h5>{category}</h5> */}
//         <h5>{item?.product?.shop?.name}</h5>
//         <div className={`${classes.btns}`}>
//           <button onClick={() => updateCartItem(item, false)}>-</button>
//           <button > {item?.count} </button>
//           <button onClick={() => updateCartItem(item, true)}>+</button>
//         </div>
//         <h5>{item?.product?.price} ₼</h5>
//         {/* <div className={`${classes.promocod}`}>
//           <span>Promokod</span>
//           <input type="text" />
//         </div> */}
//         <button className={`${classes.remove}`} onClick={() => removeCartItem(item?.id)}>
//           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
//             <path d="M15.4839 0.516129C14.7957 -0.172043 13.7634 -0.172043 13.0753 0.516129L8 5.5914L2.92473 0.516129C2.23656 -0.172043 1.2043 -0.172043 0.516129 0.516129C-0.172043 1.2043 -0.172043 2.23656 0.516129 2.92473L5.5914 8L0.516129 13.0753C-0.172043 13.7634 -0.172043 14.7957 0.516129 15.4839C0.860215 15.828 1.29032 16 1.72043 16C2.15054 16 2.58065 15.828 2.92473 15.4839L8 10.4086L13.0753 15.4839C13.4194 15.828 13.8495 16 14.2796 16C14.7097 16 15.1398 15.828 15.4839 15.4839C16.172 14.7957 16.172 13.7634 15.4839 13.0753L10.4086 8L15.4839 2.92473C16.172 2.23656 16.172 1.2043 15.4839 0.516129Z" fill="#545454" />
//           </svg>
//         </button>
//       </div>

//       <div className={classes.mobilCartItem}>
//         <input type="checkbox" className={classes.mobilCheck} onChange={(e) => handleCheckboxChange(e, item)} />
//         <div className={classes.mobilCartLeft}>
//           <Link className={`${classes.image}`}><img src={img_url + item?.product?.cover} alt={item?.product?.product_name} /></Link>
//           <div className={classes.mobilCartName}>
//             <h5>{item?.product?.product_name}</h5>
//             <p className={classes.carLists}>{uniqeCarMakes.map((car, i) => <span key={i}>{car}</span>)}</p>
//             <p className={classes.carShop}><span>{item?.product?.shop?.name}</span></p>
//             <p>{productNumber.length ? `OEM kod: ${productNumber}` : ''}</p>
//             {/* <p>OEM kod: {productNumber}</p> */}
//           </div>
//           {/* <div className={`${classes.mobilPromocod}`}>
//             <span>Promokod</span>
//             <input type="text" />
//           </div> */}
//         </div>
//         <div className={classes.mobilCartRight}>
//           <button className={classes.mobilRemove} onClick={() => removeCartItem(item?.id)}> X</button>
//           <div className={`${classes.btns}`}>
//             <button onClick={() => updateCartItem(item, false)}>-</button>
//             <button> {item?.count} </button>
//             <button onClick={() => updateCartItem(item, true)}>+</button>
//           </div>
//           <h5>{item?.product?.price} ₼</h5>
//         </div>
//       </div>

//       <ToastContainer position="bottom-right" />
//     </>
//   )
// }

// export default CartItem

import React, { useState, useContext } from "react";
import classes from "./cart-item.module.css";
import {
  deleteBasketItem,
  updateBasketItem,
} from "../../../services/basketService";
import { ToastContainer, toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthContext";
import { postWishLists } from "../../../services/wishListService";

const CartItem = (props) => {
  const { basketListsCount, setBasketListsCount } = useContext(AuthContext);
  const {
    data,
    item,
    setBasketLists,
    handleCheckboxChange,
    setSelectedItems,
    selectedItems,
  } = props;
  const { getAllWishLists } = useContext(AuthContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const img_url =
    "https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/";
  const productNumber = item?.product?.product_numbers?.map(
    (item) => item?.part_number
  );
  const carMakes = item?.product?.car_makes.map((item) => item.name);
  const uniqeCarMakes = [...new Set(carMakes)];

  const openModal = (item) => {
    setCurrentItem(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentItem(null);
  };

  const removeCartItem = async (id) => {
    const newItem = data?.filter((item) => item.id !== id);
    const removeSelected = selectedItems?.filter((item) => item.id !== id);
    const removeBasket = basketListsCount?.filter((item) => item.id !== id);

    setBasketLists(newItem);
    setSelectedItems(removeSelected);
    setBasketListsCount(removeBasket);
    try {
      await deleteBasketItem(id);
      toast.success("Məhsul silindi");
    } catch (error) {
      console.log(error);
    }

    closeModal();
  };

  const updateCartItem = async (cart, get) => {
    const newItem = data?.find((item) => item.id === cart.id);

    let countItem;
    if (get) {
      countItem = newItem.count + 1;
    } else {
      countItem = newItem.count - 1;
      if (countItem < 1) countItem = 1;
    }

    if (newItem) {
      const updatedItem = { ...newItem, count: countItem };

      setBasketLists((prevLists) =>
        prevLists.map((item) =>
          item.id === updatedItem.id ? updatedItem : item
        )
      );

      setSelectedItems((prevItems) =>
        prevItems.map((item) =>
          item.id === cart.id ? { ...item, count: countItem } : item
        )
      );

      try {
        await updateBasketItem(updatedItem);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const addToWishlistAndRemove = async (item) => {
    try {
      await postWishLists(item);
      setSelectedItems((prevItems) => [...prevItems, item]);
      toast.success("Məhsul seçilmişlərə əlavə edildi.");
      closeModal();
    } catch (error) {
      console.log("Wishlist Error:", error);
      toast.error("Seçilmişlərə əlavə edilərkən xəta baş verdi.");
    } finally {
      getAllWishLists();
    }
  };

  return (
    <>
      {/* Məhsul Elementi */}
      <div className={`${classes.cartItem}`}>
        <input
          type="checkbox"
          className={classes.check}
          onChange={(e) => handleCheckboxChange(e, item)}
        />
        <p className={`${classes.image}`}>
          <img
            src={img_url + item?.product?.cover}
            alt={item?.product?.product_name}
          />
        </p>
        <div className={classes.item__2}>
          <h5>{item?.product?.product_name}</h5>
          <p className={classes.carLists}>
            {uniqeCarMakes.map((car, i) => (
              <span key={i}>{car}</span>
            ))}
          </p>
        </div>
        <p className={classes.item__3}>
          {productNumber.length ? `OEM kod: ${productNumber}` : ""}
        </p>
        <h5>{item?.product?.shop?.name}</h5>
        <div className={`${classes.btns}`}>
          <button onClick={() => updateCartItem(item, false)}>-</button>
          <button> {item?.count} </button>
          <button onClick={() => updateCartItem(item, true)}>+</button>
        </div>
        <h5>{item?.product?.price} ₼</h5>
        <button className={`${classes.remove}`} onClick={() => openModal(item)}>
          X
        </button>
      </div>

      {/* Modal */}

      {isModalOpen && (
        <div
          className={classes.modalOverlay}
          onClick={(e) => {
            if (e.target.className.includes("modalOverlay")) closeModal();
          }}
        >
          <div className={classes.modalContent}>
            <h3>Əminsiniz?</h3>
            <h4>
              Məhsulu silmək istədiyinizdən əminsiniz? Məhsulu seçilmişlər
              siyahısına əlavə edə bilərsiniz.
            </h4>
            <div className={classes.modalButtons}>
              <span onClick={() => addToWishlistAndRemove(currentItem)}>
                Seçilmişlər
              </span>

              <button onClick={() => removeCartItem(currentItem.id)}>
                Sil
              </button>
            </div>
            <button onClick={closeModal} className={classes.closeModal}>
              X
            </button>
          </div>
        </div>
      )}

      <ToastContainer position="bottom-right" />
    </>
  );
};

export default CartItem;
