import React from "react";
import classes from "./mobil-filter.module.css";
import { FiSearch } from "react-icons/fi";
import Select from "react-select";
import { useAllCarTypes } from "../../../hooks/query/carType";
import { useCarmakes } from "../../../hooks/query/carMake";
import { useCarModels } from "../../../hooks/query/carModel";
import { useCarGenerations } from "../../../hooks/query/carGeneration";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { CustomStyles } from "../../../helpers/contants/s3";

const Mobilfilter = (props) => {
  const [showFilter, setShowFilter] = useState(true);
  const [showSearch, setShowSearch] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [carTypeId, setCarTypeId] = useState(
    Number(searchParams.get("car_type_id") ?? 0)
  );
  const [carMakeId, setCarMakeId] = useState(
    Number(searchParams.get("car_make_id") ?? 0)
  );
  const [carModelId, setCarModelId] = useState(
    Number(searchParams.get("car_model_id") ?? 0)
  );
  const [carGenerationId, setCarGenerationId] = useState(
    Number(searchParams.get("car_generation_id") ?? 0)
  );
  const [product_number, setProductNumber] = useState(
    searchParams.get("product_number") ?? ""
  );
  const ref = useRef();

  const { data: carTypeData } = useAllCarTypes({
    id_car_default: 0,
    order: "name",
    direction: "asc",
  });

  const {
    data: carMakeData,
    refetch: carMakeRefetch,
    isStale: carMakeIsStale,
  } = useCarmakes({ id_car_type: carTypeId, order: "name", direction: "asc" });

  const {
    data: carModelData,
    refetch: carModelRefetch,
    isStale: carModelIsStale,
  } = useCarModels({ id_car_make: carMakeId, order: "name", direction: "asc" });
  const {
    data: carGenerationData,
    refetch: carGenerationRefetch,
    isStale: carGenerationIsStale,
  } = useCarGenerations({
    id_car_model: carModelId,
    order: "name",
    direction: "asc",
  });

  const handleCarTypeChange = (e) => {
    if (e && e.value !== undefined) {
      setCarTypeId(e.value);
      setCarMakeId(0);
      setCarModelId(0);
      setCarGenerationId(0);
      handleCarMakeChange();
      resetSiblingValues(".css-1dimb5e-singleValue", 1);
    }
  };

  const handleCarMakeChange = (e) => {
    if (e && e.value !== undefined) {
      setCarMakeId(e.value);
      setCarModelId(0);
      setCarGenerationId(0);
      handleCarModelChange();
      resetSiblingValues(".css-1dimb5e-singleValue", 2);
    }
  };

  const handleCarModelChange = (e) => {
    if (e && e.value !== undefined) {
      setCarModelId(e.value);
      setCarGenerationId(0);
      handleCargenerationChange();
      resetSiblingValues(".css-1dimb5e-singleValue", 3);
    }
  };

  const handleCargenerationChange = (e) => {
    if (e && e.value !== undefined) {
      setCarGenerationId(e.value);
      resetSiblingValues(".css-1dimb5e-singleValue", 4);
    }
  };

  const handleClear = () => {
    setCarMakeId(0);
    setCarModelId(0);
    setCarGenerationId(0);
    handleCarMakeChange();
    handleCarModelChange();
    handleCargenerationChange();

    resetSiblingValues(".css-1dimb5e-singleValue", 3);
  };

  const resetSiblingValues = (className, index) => {
    // try {
    const resultDivs = document.querySelectorAll(className);
    resultDivs.forEach((resultDiv, i) => {
      // if(i > index - 1 && i >= index) {
      resultDiv[i].innerHTML = "";
      // }
    });
    // } catch(error) {
    //   // console.error(error); // Hata mesajını konsola yazdırabilirsiniz
    // }
  };

  useEffect(() => {
    if (carMakeIsStale && carTypeId !== 0) {
      carMakeRefetch();
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carTypeId]);

  useEffect(() => {
    if (carModelIsStale && carMakeId !== 0) {
      carModelRefetch();
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carMakeId]);

  useEffect(() => {
    if (carGenerationIsStale && carModelId !== 0) {
      carGenerationRefetch();
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carModelId]);

  const carTypeValues = carTypeData?.data?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const carMakeValues = carMakeData?.data?.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  const carModelsValues = carModelData?.data?.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  const carGenerationsValues = carGenerationData?.data?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const handleSubmitCarInfo = (e) => {
    e.preventDefault();
    const queryParams = Object.fromEntries(searchParams.entries());

    queryParams.car_type_id = carTypeId;
    queryParams.car_make_id = carMakeId;
    queryParams.car_model_id = carModelId;
    queryParams.car_generation_id = carGenerationId;
    delete queryParams.product_number;

    const updatedSearchParams = new URLSearchParams(queryParams);
    setSearchParams(updatedSearchParams);

    if (location.pathname === "/") {
      navigate(`/products?${updatedSearchParams.toString()}`);
    } else {
      navigate(`${location.pathname}?${updatedSearchParams.toString()}`);
    }
  };

  const handleSubmitProducNumber = (e) => {
    e.preventDefault();
    const queryParams = Object.fromEntries(searchParams.entries());

    delete queryParams.car_type_id;
    delete queryParams.car_make_id;
    delete queryParams.car_model_id;
    delete queryParams.car_generation_id;
    delete queryParams.shop_type;
    delete queryParams.shop_id;
    queryParams.product_number = product_number;

    const updatedSearchParams = new URLSearchParams(queryParams);
    setSearchParams(updatedSearchParams);

    if (location.pathname === "/") {
      navigate(`/product?${updatedSearchParams.toString()}`);
    } else {
      navigate(`/product?${updatedSearchParams.toString()}`);
    }
  };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const currentFilter = ref.current.offsetTop;
  //     if(window.scrollY < 150) {
  //       setIsFixed(false);
  //     } else if(window.scrollY >= currentFilter) {
  //       setIsFixed(true);
  //     }
  //   };
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, []);

  const handleFilter = () => {
    setShowFilter(true);
    setShowSearch(false);
  };

  const handleSearch = () => {
    setShowFilter(false);
    setShowSearch(true);
  };

  return (
    <section className={`${classes.mobilFilter} `}>
      <div className="container">
        {/* <div className="filterFlex">
          <button onClick={handleFilter} style={{ cursor: "pointer" }} className={showFilter ? "activeA" : ""}>Ümumi</button>
          <button onClick={handleSearch} style={{ cursor: "pointer" }} className={showSearch ? "activeA" : ""}>OEM koda görə</button>
        </div> */}
        {showFilter && (
          <>
            <form
              className={classes.commonFilter}
              onSubmit={handleSubmitCarInfo}
            >
              <Select
                className={classes.selectItem}
                onChange={handleCarTypeChange}
                placeholder={"Növ"}
                options={carTypeValues}
                value={carTypeValues?.find(
                  (option) => option.value === carTypeId
                )}
                styles={CustomStyles}
              />
              <Select
                className={classes.selectItem}
                onChange={handleCarMakeChange}
                placeholder={"Marka"}
                options={carMakeValues}
                value={carMakeValues?.find(
                  (option) => option.value === carMakeId
                )}
                styles={CustomStyles}
              />
              <Select
                className={classes.selectItem}
                onChange={handleCarModelChange}
                placeholder={"Model"}
                options={carModelsValues}
                value={carModelsValues?.find(
                  (option) => option.value === carModelId
                )}
                styles={CustomStyles}
              />
              <Select
                className={classes.selectItem}
                onChange={handleCargenerationChange}
                placeholder={"Istehsal tarixi"}
                options={carGenerationsValues}
                value={carGenerationsValues?.find(
                  (option) => option.value === carGenerationId
                )}
                styles={CustomStyles}
              />
              <div className={classes.filterBtnItems}>
                <button
                  className={`${classes.btn__filter__search} btn btn__primary search_mbl`}
                >
                  Axtar
                </button>
                <button
                  onClick={handleClear}
                  className={classes.clearBtnSelect}
                >
                  <svg
                    stroke="currentColor"
                    fill="#ffffff"
                    strokeWidth="0"
                    viewBox="0 0 1024 1024"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                  </svg>
                </button>
              </div>
            </form>
            <form className="oemCode" onSubmit={handleSubmitProducNumber}>
              <input
                type="text"
                placeholder="OEM koda görə axtar"
                value={product_number}
                onChange={(e) => setProductNumber(e.target.value)}
              />
              <button
                className={`${classes.btn__filter__search} btn btn__primary`}
              >
                <FiSearch fontSize="2rem" />
              </button>
            </form>
          </>
        )}
        {/* {
          showSearch &&
          <form className='oemCode' onSubmit={handleSubmitProducNumber}>
            <input
              type="text"
              placeholder="OEM koda görə axtar"
              value={product_number}
              onChange={(e) => setProductNumber(e.target.value)}
            />
            <button className={`${classes.btn__filter__search} btn btn__primary`}><FiSearch fontSize="2rem" /></button>
          </form>
        } */}
      </div>
    </section>
  );
};

export default Mobilfilter;
