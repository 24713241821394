import axios from "axios";

export const baseURL = "https://api.avtopro.az/api";


// Create a new Axios instance with default configurations
const request = axios.create({
  baseURL: baseURL,
  withCredentials: false, // Send cookies and other authentication headers with requests
  headers: {
    'Accept': 'application/json', // Set the Accept header to receive JSON responses
    'Content-Type': 'application/json', // Set the Content-Type header to send JSON requests
  },
});

// Request interceptor
request.interceptors.request.use(
  (config) => {
    // Modify headers or perform other tasks before the request is sent
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

// Response interceptor
request.interceptors.response.use(
  (response) => {
    // Process response data before it's returned
    return response;
  },
  (error) => {
       
    if(error.response.status === 401 && error.config.url !== '/auth/login'){
        localStorage.removeItem('token')
        window.location = "/register";
    }
    
    return Promise.reject(error);

  }
);

export default request;



