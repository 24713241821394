import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import classes from "./shop-inner.module.css"
import SimilarProduct from './similarProduct';
import Shopinfo from '../shopinfo/shopinfo'
import Filter from '../../../components/ui/filter/filter'
import Mobilfilter from '../../../components/ui/mobilfilter/mobilfilter'
import Modal from '../../../modals/Modal'
import { TbClockHour4 } from "react-icons/tb"
import { TfiWorld } from "react-icons/tfi"
import { Link } from 'react-router-dom'
import { BsFillTelephoneFill, BsEyeFill, BsInfoCircle } from "react-icons/bs"
import { HiLocationMarker } from 'react-icons/hi'
import { GrYoutube } from 'react-icons/gr'
import Menu from '../../../components/ui/menu/index'
import Breadcrumb from '../../../components/ui/breadcrumb/breadcrumb'
import { useSalon } from '../../../hooks/query/shop';
import { useSalons } from '../../../hooks/query/products';
import ShopProduct from '../../../components/shopProduct';
import Meta from '../../../components/ui/meta';
import ParentCategory from '../../../components/parentCategory';
import Productsalon from "../../../components/productautosalon/product"
import { useInfiniteQuery } from '@tanstack/react-query';
import { getSalons } from '../../../services/productService';
import WheelFilter from '../../../components/wheel_filter/wheel_filter';
import SalonFilter from '../../../components/salon_filter/filter';
import SalonFilterMobile from '../../../components/salon_filter/mobilfilter';
import MobilWheelfilter from '../../../components/mobil_wheel_filter/mobil_wheel_filter';


const Shopinner = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { productId } = useParams()
  // const urlArray = productId.split("-");
  // const id = urlArray[urlArray.length - 1];
  const { shopId } = useParams()
  const { data } = useSalon(shopId);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const [orderSelectValue] = useState()
  const [showFilter, setShowFilter] = useState(1)
  const [carMakeId, setCarMakeId] = useState("");

  const { data: productsDiscountData, isFetching: productsIsFetching } = useSalons({
    discount: "yes",
    shop_id: shopId,
    car_make_id: shopId,
    paginate: 30
  });


  const params = {
    paginate: 30,
    page: searchParams.get("page") ?? 1,
    shop_id: shopId
  }

  if(searchParams.get("order")) params["order"] = searchParams.get("order");
  // if(searchParams.get("category_id")) params["category_id"] = searchParams.get("category_id");
  if(searchParams.get("direction")) params["direction"] = searchParams.get("direction");
  if(searchParams.get("shop_type")) params["shop_type"] = searchParams.get("shop_type");
  if(searchParams.get('make_id')) params["make_id"] = searchParams.get('make_id');
  if(searchParams.get('model_id') ?? 0) params["model_id"] = searchParams.get('model_id');
  if(searchParams.get('year')) params["year"] = searchParams.get('year');
  // if(searchParams.get('height_id')) params["height_id"] = searchParams.get('height_id');
  // if(searchParams.get('diameter_id')) params["diameter_id"] = searchParams.get('diameter_id');
  // if(searchParams.get('width_id')) params["width_id"] = searchParams.get('width_id');


  // const {
  //   data: dataProducts,
  //   isLoading,
  //   isFetching,
  //   fetchNextPage,
  //   hasNextPage,
  //   isFetchingNextPage,
  // } = useInfiniteQuery(
  //   ['products_all', params],
  //   async ({ pageParam = 1 }) => {
  //     const result = await getSalons({ ...params, page: pageParam, random_paginate: 1 });
  //     console.log(result?.data); // async/await ile loglama
  //     return result;
  //   },
  //   {
  //     getNextPageParam: (lastPage, allPages) => {
  //       const nextPage = allPages.length + 1;
  //       return lastPage?.data?.length !== 0 ? nextPage : undefined;
  //     },
  //   }
  // );

  const {
    data: dataProducts,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ['products_all', params],
    ({ pageParam = 1 }) => getSalons({ ...params, page: pageParam, random_paginate: 1 }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1
        return lastPage?.data?.length !== 0 ? nextPage : undefined
      },
    }
  )

  const image_url = 'https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/storage/' + data?.data?.logo;
  const image_url_cover = 'https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/storage/' + data?.data?.cover;

  const website = data?.data?.shop_information[0]?.website;
  const workTime = data?.data?.shop_information[0]?.work_time;

  const handleLinkClick = (url) => {
    window.open(url, '_blank');
  };


  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOrder = (e) => {
    const queryParams = Object.fromEntries(searchParams.entries());
    switch(e.value) {
      case 1:
        queryParams.order = "id";
        queryParams.direction = "desc";
        break;
      case 2:
        queryParams.order = "price";
        queryParams.direction = "desc";
        break;
      case 3:
        queryParams.order = "price";
        queryParams.direction = "asc";
        break;
      case 4:
        delete queryParams.order;
        delete queryParams.direction;
        queryParams.shop_type = 3;
        break;
      default:
        queryParams.order = "id";
        queryParams.direction = "desc";
        break;
    }


    const updatedSearchParams = new URLSearchParams(queryParams);
    navigate(`${location.pathname}?${updatedSearchParams.toString()}`);
  }


  const handleShowFilter = (id) => {
    setShowFilter(id)
    if(id === 1) {
      navigate(`${location.pathname}??shop_type=${3}&shop_id=${shopId}`);
    }
  }
  // console.log(data?.data);
  // console.log("data?.data?.tire_sale_type ");
  let filterContent
  filterContent = <SalonFilter params={{ shop_id: shopId }} />
  // if(data?.data?.tire_sale_type === 1 || data?.data?.tire_sale_type === 3) {
  //   filterContent = <Filter params={{ shop_id: shopId }} />
  // } else if(data?.data?.tire_sale_type === 2 || data?.data?.tire_sale_type === 3) {
  //   filterContent = <SalonFilter />
  // }


  // let filterContentMobil
  // if(data?.data?.tire_sale_type === 1 || data?.data?.tire_sale_type === 3) {
  //   filterContentMobil = <Mobilfilter params={{ shop_id: shopId }} />
  // } else if(data?.data?.tire_sale_type === 2 || data?.data?.tire_sale_type === 3) {
  //   filterContentMobil = <MobilWheelfilter />
  // }

  const page = {
    pages: [
      {
        link: "/autosalon",
        content: "Avtosalonlar",
      },

    ],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const img_url = "https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/storage/";

  const title = ` Avtosalonlar - ${data?.data?.name} | avtopro.az `
  const keywords = "avtosalonlar, aksesuar magazası, zapcast magazası, avtomobil, nəqliyyat, nəqliyyat vasitəsi, avtopro, aksesuar, təkərlər, əyləc bəndi, yağlar"
  const description = `${(data?.data?.name ? data?.data?.name : "") + ' - nəqliyyat vasitələrinə aid hər növ ehtiyyat hissələri və aksesuarların satışı| avtopro.az'}`;
  const image = `${data?.data?.logo ? img_url + data?.data?.logo : ''}`

  const siteURL = "https://avtopro.az"
  const { pathname } = useLocation();
  const pageLink = pathname;

  const shopAddress = data?.data?.shop_information[0]?.address;
  const shopPhone = data?.data?.shop_information[0]?.phone1;
  const phoneClear = shopPhone;
  let reversedString = "";
  let resultPhone = "";

  if(phoneClear === "" || phoneClear === null || phoneClear === undefined) {
  } else {
    reversedString = phoneClear.replace(/\s|\(|\)/g, '').split('').reverse().slice(0, 9).reverse().join('');
    resultPhone = "+994" + reversedString;
  }
  const currentUrl = window.location.href;

  useEffect(() => {
    const carMakeIdValue = data?.data?.car_make_id;
    if(carMakeIdValue && carMakeIdValue !== 0 && carMakeIdValue !== "") {
      setCarMakeId(carMakeIdValue);
    }
  }, [data]);

  // useEffect(() => {
  //   // Yükleme tamamlandığında ve veriyi kontrol et
  //   if(!isLoading && !isFetching) {
  //     // dataProducts tam olarak yüklenmişse, kontrol yap
  //     const data = dataProducts?.pages?.[0]?.data?.data || [];

  //     if(Array.isArray(data) && data.length === 0) {
  //       navigate('/not-found');
  //     }
  //   }
  // }, [isLoading, isFetching, dataProducts, navigate]);

  // // Verinin yüklenmesini bekleyin ve yüklenirken bir yükleme mesajı gösterin
  // if(isLoading || isFetching) {
  //   return <div>Loading...</div>;
  // }



  return (
    <>

      <Meta title={title} keywords={keywords} description={description} image={image} data={data} shopAddress={shopAddress} currentUrl={currentUrl} siteURL={siteURL} pageLink={pageLink} shopLogo={image} resultPhone={resultPhone} isShopinnerPage={true} />

      {/* <script type="application/ld+json">
        {JSON.stringify(postalSchema)}
      </script> */}
      <Menu />
      <Breadcrumb page={page} />
      <div className={`${classes.auto_section}`}>

        <section className={classes.shopImgCover}>
          {/* <img src={image_url} alt={data?.data?.name} />   */}
          <img src={image_url_cover} alt={data?.data?.name} />
        </section>

        <div className={`${classes.auto_top_sect}`}>

          {/* <div className={`${classes.description_content} container showDesktopFilter`}>
            <div
              className={`${classes.desc_info} ${isExpanded ? classes.expanded : ''}`}
              dangerouslySetInnerHTML={{ __html: data?.data?.information ? `${data?.data?.information}` : "" }}
            ></div>
            <div className={`${classes.more_text} ${isExpanded ? classes.clicked : ''}`}>
              <span onClick={handleToggle} >Daha çox</span>
            </div>
          </div> */}

          <div className={`${classes.description_content} ${classes.shop_detail_mob} container showMobilFilter shop_detail_mob`}>

            <div className={classes.shopImgMob}>
              <img src={image_url} alt={data?.data?.name} />
            </div>
            <div className={classes.shop_right_mob}>
              {/* <h3 className={classes.shop_name_hd}>{data?.data?.name}</h3> */}
              <p><Link  > {data?.data?.shop_information[0]?.address}</Link></p>
              <p><Link to={`tel:+994 ${data?.data?.shop_information[0]?.phone1?.replace(/^0/, '') || ''}`}> {data?.data?.shop_information[0]?.phone1}</Link></p>
              <p><b>{data?.data?.products_count} elan</b> </p>
              <p><BsEyeFill fill="#757575" /> {data?.data?.views}</p>
            </div>
          </div>


          <Shopinfo data={data} handleLinkClick={handleLinkClick} workTime={workTime} website={website} />


        </div>

        <div className={`container ${classes.mobil__shop__info}`}>
          <p onClick={openModal}><BsInfoCircle fill="#68a834" /> Haqqında </p>
        </div>
        {/* {data?.data?.tire_sale_type === 3 ? (<div className={`${classes.showFilterBtns} showMobilFilter`}>

          <button onClick={() => handleShowFilter(1)} className={showFilter === 1 ? "activeA" : ""}>Ehtiyyat hissəsi</button>
          <button onClick={() => handleShowFilter(2)} className={showFilter === 2 ? "activeA" : ""}>Disklər və təkərlər </button>

        </div>) : ""}
        {data?.data?.tire_sale_type !== 3 && filterContentMobil}
        {data?.data?.tire_sale_type === 3 && showFilter === 1 ? <Mobilfilter params={{ shop_id: shopId }} /> : ""}
        {data?.data?.tire_sale_type === 3 && showFilter === 2 ? <MobilWheelfilter /> : ""}

        {data?.data?.tire_sale_type === 3 ? (<div className={`${classes.showFilterBtns} showDesktopFilter`}>

          <button onClick={() => handleShowFilter(1)} className={showFilter === 1 ? "activeA" : ""}>Ehtiyyat hissəsi</button>
          <button onClick={() => handleShowFilter(2)} className={showFilter === 2 ? "activeA" : ""}>Disklər və təkərlər </button>

        </div>) : ""} */}

        {/* {data?.data?.tire_sale_type !== 3 && filterContent}

        {data?.data?.tire_sale_type === 3 && showFilter === 1 ? <SalonFilter params={{ shop_id: shopId, carMakeId: carMakeId }} /> : ""}
        {data?.data?.tire_sale_type === 3 && showFilter === 2 ? <WheelFilter /> : ""} */}

        <div className={`${classes.filter_items_desk}`}>
          <SalonFilter params={{ shop_id: shopId, carMakeId: carMakeId }} />
        </div>
        <div className={`${classes.filter_items_mobile}`}>
          <SalonFilterMobile params={{ shop_id: shopId, carMakeId: carMakeId }} />
        </div>


        <ShopProduct
          productsDiscount={productsDiscountData}
          productsIsFetching={productsIsFetching}
          title="Endirimli məhsullar"
          discount={true}
        />

        {/* <ParentCategory shopId={shopId} /> */}


        <SimilarProduct category_id={shopId} product_id={data?.data?.id} />

        {/* <Productsalon

          category_id={shopId} product_id={data?.data?.id}

          end="end"
          orderSelectValue={orderSelectValue}
          handleOrder={handleOrder}

          shopId={shopId}
          data={dataProducts}
          isLoading={isLoading}
          isFetching={isFetching}
          error
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
        /> */}


        <Modal isOpen={isModalOpen} onClose={closeModal} >
          <div className={`shop__info__modal ${classes.shop_modal_sect}`}>
            <div className='shop__info__item'>
              <button><TbClockHour4 /></button>
              <div className='right__info'>
                <span>İş saatları</span>

                <p>{workTime ? workTime : "------"}</p>
              </div>
            </div>
            <div className='shop__info__item'>
              <button><TfiWorld /> </button>
              <div className='right__info'>
                <span>Web səhifə</span>
                <p><Link onClick={() => handleLinkClick(`https://${website}`)} > {website ? website : "--------"}</Link></p>
              </div>
            </div>
            <div className='shop__info__item'>
              <button><BsFillTelephoneFill /> </button>
              <div className='right__info'>
                <span>Əlaqə nömrəsi</span>
                <p><Link to={`tel:+994 ${data?.data?.shop_information[0]?.phone1?.replace(/^0/, '') || ''}`}> {data?.data?.shop_information[0]?.phone1}</Link></p>
              </div>
            </div>
            <div className='shop__info__item'>
              <button><HiLocationMarker /> </button>
              <div className='right__info'>
                <span>Ünvan</span>
                <p><Link  > {data?.data?.shop_information[0]?.address}</Link></p>
              </div>
            </div>
            <div className='shop__text'>
              {/* <Link className='shop__subscribe'> <GrYoutube color="#FE0000" fontSize="2.2rem" /> Abunə ol</Link> */}
              {data?.data?.information && <h3>Mağaza haqqında</h3>}
              <p dangerouslySetInnerHTML={{ __html: data?.data?.information ? `${data?.data?.information}` : "" }}></p>
            </div>

          </div>
        </Modal>
      </div>
    </>
  )
}


export default Shopinner


