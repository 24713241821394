import React, { useState } from "react";
import Modal from "../../../modals/Modal";
import "../../../assets/styles/form.css";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./yup";
import axios from "axios";
import { baseURL } from "../../../helpers/api/request";
import Select from 'react-select';
import { useCities } from "../../../hooks/query/cities";
import { CustomStyles } from "../../../helpers/contants/s3";
import InputMask from "react-input-mask";



export const ModalNav = ({ isModalOpenNav, setIsModalOpenNav }) => {

    const [selectedFile, setSelectedFile] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const screenSize = window.innerWidth;
    const [loading, setLoading] = useState(false);
    const { data: cities } = useCities()
    const [previewImage, setPreviewImage] = useState(null);



    const citySelect = cities?.data?.map((city) => {
        return {
            value: city.id,
            label: city.name
        }
    })


    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setPreviewImage(reader.result);
        };
    };


    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        setSuccessMessage('');
        setLoading(true);
        data.phone = data.phone.replaceAll(' ', '').replaceAll('-', '').replaceAll('(', '').replaceAll(')', '');
        try {
            const response = await axios.post(`${baseURL}/shops`, data, {
                headers: { "Content-Type": "multipart/form-data" },
            })
            setSuccessMessage(response?.data?.data?.message);

        } catch(error) {
            setSuccessMessage(' Göndərilmə zamanı xəta baş verdi');
        }
        finally {
            setLoading(false)
            setTimeout(() => {
                setIsModalOpenNav(false);
                reset()
                setSuccessMessage('');
                setSelectedFile(null)
            }, 3000);
        }
    };

    const closeModal = () => {
        setIsModalOpenNav(false);
    };

    return (
        <Modal isOpen={isModalOpenNav} onClose={closeModal} width={500} height={screenSize > 600 ? 85 : 95}>
            <form onSubmit={handleSubmit(onSubmit)} className={`open__form__modal form_shop_modal`}>
                <h3>Online mağaza aç</h3>
                <div className="form__item">
                    <span>Mağazanın adı*</span>
                    <input type="text" placeholder="Mağaza adı" {...register("shopName")} />
                    <p>{errors.shopName?.message}</p>
                </div>
                <div className="form__item">
                    <span>Mağazanın ünvanı*</span>
                    <input type="text" placeholder="Mağazanın ünvanı" {...register("address")} />
                    <p>{errors.address?.message}</p>
                </div>
                <div className="repairerSelect form__image">
                    <span className="select__span">Şəhər</span>
                    <Controller
                        name="city_id"
                        className="selectRepairer"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Select
                                placeholder={'Bütün şəhərlər '}
                                options={citySelect}
                                styles={CustomStyles}
                                onChange={(selectedOption) => {
                                    onChange(selectedOption.value);
                                }}
                            />
                        )}
                    />
                    <p>{errors.city_id?.message}</p>
                </div>
                <div className="form__item">
                    <span>Ad və Soyad</span>
                    <input type="text" placeholder="Ad və Soyad" {...register("relevantPerson")} />
                    <p>{errors.relevantPerson?.message}</p>
                </div>
                <div className="form__item">
                    <span>E-mail</span>
                    <input type='email' placeholder="E-mail" {...register("email")} />
                    <p>{errors.email?.message}</p>
                </div>
                <div className="form__item">
                    <span>Əlaqə nömrəsi</span>
                    <InputMask mask="(999) 999-99-99" placeholder="(050) 333-xx-xx" {...register('phone')} />
                    <p>{errors.phone?.message}</p>
                </div>
                <div className="form__item">
                    <span>İş saatları:</span>
                    <input type="text" placeholder="Hər gün 09:00-19:00" {...register("work_time")} />
                    <p>{errors.work_time?.message}</p>
                </div>


                <div className="form__item form__image">
                    {/* <span>Şəkil </span> */}
                    {selectedFile && (
                        <div className="image-preview">
                            <img src={previewImage} alt="Preview" className="preview-image" />
                            <div className="remove-button" onClick={() => setSelectedFile(null)}>&#10006;</div>
                        </div>
                    )}
                    {
                        selectedFile === null && <label className={"form__img"}>
                            Logonu yükləyin
                            <input type="file" {...register("logo")} onChange={handleFileSelect} />
                        </label>
                    }
                    <p>{errors.logo?.message}</p>
                </div>


                {successMessage && <h4 > {successMessage}</h4>}
                <button type="submit" className="btn btn__primary" >{loading ? (<>Göndərilir &nbsp;	 <i className="fas fa-circle-notch fa-spin"></i></>) : "Göndər"}</button>
            </form>
        </Modal>
    );
};





