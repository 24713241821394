import React, { useEffect } from "react";
import Menu from "../../components/ui/menu";
import Meta from "../../components/ui/meta";
import Breadcrumb from "../../components/ui/breadcrumb/breadcrumb";
import { Link, useParams } from "react-router-dom";
import { FaPhoneAlt, FaUser } from "react-icons/fa";
import { SlArrowLeft } from "react-icons/sl";
import { IoLocationSharp } from "react-icons/io5";
import CarCard from "../../components/carcard/carcard";
import styles from "../../pages/carnumber/carnumber.module.css";
import { useNumber } from "../../hooks/query/registerNumber";
import { svgFlagIconone } from "../../assets/icons/Icons";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getRegisterNumber } from "../../services/registerNumberService";
import SkeletonPost from "../../components/skletons/SkeletonPost";

const CarDetail = () => {
  const { id } = useParams();

  const { data, isFetching } = useNumber(id);

  const params = {
    paginate: 30,
  };

  const {
    data: dataRegister,
    fetchNextPage,
    hasNextPage,
    isFetching: isFetchingRegister,
  } = useInfiniteQuery(
    ["register-number", params],
    ({ pageParam = 1 }) => getRegisterNumber({ ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage?.data?.length !== 0 ? nextPage : undefined;
      },
    }
  );

  useEffect(() => {
    let fetching = false;

    const handleScroll = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } =
        event.target.scrollingElement;

      if (
        !fetching &&
        scrollHeight - scrollTop <= clientHeight * 1.2 &&
        hasNextPage
      ) {
        fetching = true;
        const newData = await fetchNextPage(); // This calls the API to fetch the next page.

        const fetchDataTrue = newData?.data?.pages?.map(
          (item) => item?.data?.data
        );
        const fetchData = fetchDataTrue?.find((item) => item?.length < 30);
        if (fetchData) {
          document.removeEventListener("scroll", handleScroll);
        }

        fetching = false;
      }
    };

    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, [fetchNextPage, hasNextPage]);

  const dateTimeString = data?.data?.created_at;
  const date = new Date(dateTimeString);

  const formattedDate = date.toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  const year = formattedDate.split("/")[2];
  const month = formattedDate.split("/")[0];
  const day = formattedDate.split("/")[1];
  // const hour = formattedDate.split(',')[1];

  const letterNumber = `${data?.data?.first_letter}${data?.data?.second_letter}`;

  let currency = "";

  if (data?.data?.currency === 1) {
    currency = "₼";
  } else if (data?.data?.currency === 2) {
    currency = "$";
  } else if (data?.data?.currency === 3) {
    currency = "EURO";
  }

  const handleRelatedProductClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const page = {
    pages: [
      {
        link: "/licenseplate",
        content: "Qeydiyyat Nişanları",
      },
      {
        link: "",
        content:
          !isFetchingRegister &&
          ` ${data?.data?.region?.region_number}-${
            !isFetching && letterNumber
          }-${data?.data?.number}`,
      },
    ],
  };

  // const bottom__nav = [
  //   {
  //     id: 1,
  //     url: "/",
  //     icon: <FaPen fontSize="2rem" />,
  //     text: "Düzəliş et",
  //   },
  //   {
  //     id: 2,
  //     url: "/",
  //     icon: <BsTrash fontSize="2rem" />,
  //     text: "Elanı sil",
  //   },
  //   {
  //     id: 3,
  //     url: "/",
  //     icon: <BsArrowUp fontSize="2rem" />,
  //     text: "Elanı irəli çək",
  //   },
  //   {
  //     id: 4,
  //     url: "/",
  //     icon: <FaChessQueen fontSize="2rem" />,
  //     text: "Elanı premium et",
  //   },
  //   {
  //     id: 5,
  //     url: "/",
  //     icon: <BiMessageAltError fontSize="2rem" />,
  //     text: "Şikayət et",
  //   },
  //   {
  //     id: 6,
  //     url: "/",
  //     icon: <FaHeart fontSize="2rem" />,
  //     text: "Seçilmişlərə əlavə et",
  //   },
  // ];

  const currentUrl = window.location.href;
  const title = `Qeydiyyat Nişanı - ${data?.data?.region?.region_number}-${
    !isFetching && letterNumber
  }-${data?.data?.number} | avtopro.az`;
  const keywords =
    "qeydiyyat nişanları, avtomobil nomresi, masin nomresi, avtomobil, nəqliyyat, nəqliyyat vasitəsi, zapcast, avtopro, aksesuar, motosiklet";
  const description =
    "Nəqliyyat vasitələrinə aid qeydiyyat nişanlarının satışı avtopro.az'da";
  const siteURL = "https://avtopro.az";
  const shopName = `${data?.data?.region?.region_number}-${
    !isFetching && letterNumber
  }-${data?.data?.number}`;
  // console.log(shopName);
  return (
    <>
      <Meta
        title={title}
        keywords={keywords}
        description={description}
        data={data}
        currentUrl={currentUrl}
        shopName={shopName}
        siteURL={siteURL}
        isCarnumPage={true}
      />
      <Menu />
      <Breadcrumb page={page} none="dnone" />
      <section className="container">
        <Link className={`${styles.lnone_detail}`} to="/licenseplate">
          <SlArrowLeft color="#000" fontSize="2rem" />
        </Link>
        <h2 className={`${styles.head}`}>QEYDİYYAT NİŞANI</h2>
        <div className={`${styles.detail_desc}`}>
          {!isFetching && (
            <div className={`${styles.card__head} ${styles.card__head_detail}`}>
              <p>
                {svgFlagIconone}
                <span>AZ</span>
              </p>
              <h3>
                {data?.data?.region?.region_number}-{letterNumber}-
                {data?.data?.number}
              </h3>
            </div>
          )}

          <h4>
            {data?.data?.price} {currency}
          </h4>
          <div className={`${styles.card_parts} ${styles.card_part_1}`}>
            <p>
              Yerləşmə tarixi:
              <strong>
                {day}/{month}/{year}
              </strong>
            </p>
            <p>
              Elanın nömrəsi:<strong>{data?.data?.id}</strong>
            </p>
            <p>
              Baxış sayı:<strong>{data?.data?.views}</strong>
            </p>
          </div>
          <div className={styles.card_parts}>
            <p>
              <FaUser fontSize="1.5rem" color="#BEC4D4" />
              {data?.data?.author_name}
            </p>
            <p>
              <FaPhoneAlt fontSize="1.5rem" color="#BEC4D4" />

              <Link
                style={{ color: "#181818" }}
                to={`tel:+994${
                  data?.data?.author_phone?.replace(/^0/, "") || ""
                }`}
              >
                {data?.data?.author_phone}
              </Link>
            </p>
          </div>
          <h4>
            <IoLocationSharp color="#68A834" fontSize="2rem" />
            {data?.data?.city?.name}
          </h4>
        </div>

        {/* <ul className={`${styles.detail_menu}`}>
          {bottom__nav.map((item) => (
            <li key={item.id}>
              <Link to={item.url}>
                {item.icon}
                <span> {item.text}</span>
              </Link>
            </li>
          ))}
        </ul> */}
        <h2>Son elanlar</h2>

        <div className={`${styles.cards__sklet}`}>
          {isFetchingRegister &&
            [...Array(12).keys()].map((i) => {
              return <SkeletonPost key={i} />;
            })}
        </div>

        <div className={`${styles.cards}`}>
          {dataRegister?.pages?.map((page) =>
            page?.data?.data?.map((card) => (
              <CarCard
                key={card.id}
                card={card}
                isFetching={isFetchingRegister}
                handleRelatedProductClick={handleRelatedProductClick}
              />
            ))
          )}
        </div>

        <div className={`${styles.cards__sklet}`}>
          {isFetchingRegister &&
            [...Array(12).keys()].map((i) => {
              return <SkeletonPost key={i} />;
            })}
        </div>
      </section>
    </>
  );
};

export default CarDetail;
