import classes from "./detail.module.css";
import DetailSlider from "./detailslider";
import Detailtable from "./detailtable";
import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../components/ui/breadcrumb/breadcrumb";
import Menu from "../../components/ui/menu/index";
import { useProduct } from "../../hooks/query/products";
import SimilarProduct from "./similarProduct";
import Meta from "../../components/ui/meta";
// import img from "../../assets/images/kaptal.png"
import DetailSliderMobil from "./detailSliderMobil";
import { getBaskets, postProduct } from "../../services/basketService";
import { ToastContainer, toast } from "react-toastify";
import "./map.css";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import { useEffect, useState, useRef, useContext } from "react";
// import { ModalNav } from "../../layouts/navbar/modalNavbar/modalNav";
import { ModalCall } from "./modalcall";
import { LocationIcon } from "../../assets/icons/Icons";
import { BsTelephoneFill } from "react-icons/bs";
import { AuthContext } from "../../context/AuthContext";
import { MenuContext } from "../../context/MenuContext";
// import { baseURL } from "../../helpers/api/request";
// import { FaHeart } from "react-icons/fa";
// import {CgDanger} from "react-icons/cg";
// import {EyeCardIcon} from "../../assets/icons/Icons"
import detailImage from "../../assets/images/detail_image.png";
import {
  deleteWishlistsItem,
  postWishLists,
} from "../../services/wishListService";
import { FaHeart } from "react-icons/fa";

const Detail = (props) => {
  const navigate = useNavigate();
  const { isAuthenticated, basketListsCount, setBasketListsCount } =
    useContext(AuthContext);
  const { setShowBasket } = useContext(MenuContext);
  const { productId } = useParams();
  const urlArray = productId.split("-");
  const id = urlArray[urlArray.length - 1];
  const { data, isFetching } = useProduct(id);
  const [addToCart, setAddToCart] = useState(false);
  const [loading, setLoading] = useState(false);
  const [basket, setBasket] = useState(false);
  const [basketLists, setBasketLists] = useState([]);
  const [isModalOpenCall, setIsModalOpenCall] = useState(false);

  const mapContainer = useRef(null);
  const mapLocation = useRef(null);
  const [lng] = useState(49.94901057127427);
  const [lat] = useState(40.41384243911918);
  const [zoom] = useState(9);
  const [API_KEY] = useState("j4WWgdwepZdF7D5WbiTh");

  // const openModal = () => {
  //   setIsModalOpenCall(true);
  // };

  const sendData = {
    product_id: data?.data?.id,
    count: 1,
  };

  const [locations, setLocations] = useState([]);

  useEffect(() => {
    if (!isFetching) {
      const shopMap = data?.data?.shop?.shop_information?.map((item) => {
        return {
          lng: item?.longitude,
          lat: item?.latitude,
        };
      });

      setLocations((p) => [...locations, shopMap].flat(1));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  useEffect(() => {
    if (mapLocation.current) return; //stops map from intializing more than once
    mapLocation.current = new maplibregl.Map({
      container: mapContainer.current,
      style: `https://api.maptiler.com/maps/streets-v2/style.json?key=${API_KEY}`,
      center: [lng, lat],
      zoom: zoom,
    });
    // map.current.addControl(new maplibregl.NavigationControl(), 'top-right');
  });


  locations?.map((location) => {
    if (locations[0] === undefined) {
      return navigate("/not-found");
    }
    if (location.lng && location.lat) {
      return new maplibregl.Marker({ color: "#FF0000" })
        .setLngLat([location.lng, location.lat])
        .addTo(mapLocation.current);
    }
  });


  const sendDataSmall = {
    product_id: data?.data?.id,
    count: 1,
    product: {
      cover: `${data?.data?.images[0]?.directory}/${data?.data?.images[0]?.file_name}`,
      price: data?.data?.price,
      product_name: data?.data?.product_name,
    },
    id: data?.data?.id,
  };

  const handleProduct = async () => {
    setLoading(true);
    try {
      const response = await postProduct(sendData);
      if (response.success) {
        toast.success("Səbətə əlavə edildi");
      }
      setBasketListsCount([...basketListsCount, sendDataSmall]);
      setAddToCart(true);
      setShowBasket(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOrderProduct = async () => {
    try {
      const response = await postProduct(sendData);
      setBasketListsCount([...basketListsCount, sendData]);
      toast.success("Səbətə əlavə edildi");
      if (response.success) {
        navigate("/cart/cartlist");
      }
      setAddToCart(true);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllBaskets = async () => {
    try {
      const response = await getBaskets();
      const data = await response?.data;
      setBasketLists(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getAllBaskets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const checkId = basketLists?.some(
      (basket) => basket.product_id === Number(id)
    );
    setBasket(checkId);
  }, [basketLists, id]);

  let dateString = data?.data?.created_at;
  let date = new Date(dateString);
  let formattedDate = "";
  if (!isNaN(date.getTime())) {
    const parts = date.toISOString().substring(0, 10).split("-");
    const outputDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    formattedDate += outputDate;
  }

  const adress = data?.data?.shop?.shop_information[0]?.address;
  const phone1 = data?.data?.shop?.shop_information[0]?.phone1;
  const phone2 = data?.data?.shop?.shop_information[0]?.phone2;
  const price1 = data?.data?.price;
  const price2 = data?.data?.old_price;

  // const parseData = new DOMParser().parseFromString(data?.data?.information, 'text/html');
  // const textContent = parseData.documentElement.textContent;
  // const brandName = textContent.replace(/(.*)&nbsp;&nbsp;.*/, '$1');

  const parseData = data?.data?.information;
  const textContent = parseData;
  const brandName = textContent;

  let formattedNumber = phone1;
  if (phone1) {
    formattedNumber = String(phone1.replace(/\s+/g, ""));
  }

  let contentBrandName;
  if (
    brandName === "undefined" ||
    brandName === undefined ||
    brandName === null ||
    brandName === "null"
  ) {
    contentBrandName = "";
  } else {
    contentBrandName = brandName;
  }

  let priceNew;
  if (
    price1 === "0.00" ||
    price1 === 0.0 ||
    price1 === null ||
    price1 === "null" ||
    typeof price1 === "undefined" ||
    price1 === undefined
  ) {
    priceNew = "";
  } else {
    priceNew = `${price1} ₼`;
  }

  let priceOld;
  if (
    price2 === "0.00" ||
    price2 === 0.0 ||
    price2 === null ||
    price2 === "null" ||
    typeof price2 === "undefined" ||
    price2 === undefined
  ) {
    priceOld = ``;
  } else {
    priceOld = `${price2} ₼`;
  }

  let goShop;
  if (data?.data?.shop?.shop_type === 2) {
    goShop = `/shops/${data?.data?.shop_id}?shop_type=2&shop_id=${data?.data?.shop_id}`;
  } else if (data?.data?.shop?.shop_type === 1) {
    goShop = `/dealers/${data?.data?.shop_id}?shop_type=1&shop_id=${data?.data?.shop_id}`;
  }

  const pageLink =
    data?.data?.breadcrumb && data?.data?.breadcrumb.length > 0
      ? data?.data?.breadcrumb.map((bread) => {
          return {
            link: `/products?category_id=${bread.id}`,
            content: bread?.category_name,
          };
        })
      : [];

  const image_url = `https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/storage/`;
  const seo_image_url = `https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/`;


  const page = {
    pages: [
      {
        link: "/products",
        content: " Bütün məhsullar",
      },
      ...pageLink,
      {
        link: `/products?category_id=${data?.data?.category?.id ?? ""}`,
        content: data?.data?.category?.category_name ?? "",
      },
    ],
  };

  const shopLogo = image_url + data?.data?.shop?.logo;
  const shopName = data?.data?.shop?.name;

  const title = `${data?.data?.product_name}  | avtopro.az`;
  // const keywords = `${data?.data?.product_name ? data?.data?.product_name : ''}`
  const keywords =
    "oem, məhsullar, vin, ehtiyyat hissələri, avtomobil, nəqliyyat vasitəsi, zapcast,  avtopro, aksesuar, ağır texnika, motosiklet, təkərlər, əyləc bəndi, yağlar";
  const description = `${
    (data?.data?.product_name ? data?.data?.product_name : "") +
    " - nəqliyyat vasitələrinə aid hər növ ehtiyyat hissələri və aksesuarların satışı | avtopro.az"
  }`;
  const image = `${
    data?.data?.images[0]?.file_name
      ? seo_image_url +
        data?.data?.images[0]?.directory +
        "/" +
        data?.data?.images[0]?.file_name
      : ""
  }`;

  const searchTerm = "Avtoprodan yeni müştəri";
  const encodedSearchTerm = encodeURIComponent(searchTerm);
  const phoneClear = phone1;
  let reversedString = "";
  let resultPhone = "";

  if (phoneClear === "" || phoneClear == null || phoneClear === undefined) {
  } else {
    reversedString = phoneClear
      .replace(/\s|\(|\)/g, "")
      .split("")
      .reverse()
      .slice(0, 9)
      .reverse()
      .join("");
    resultPhone = "994" + reversedString;
  }

  const urlWp = `https://wa.me/${resultPhone}/?text=${encodedSearchTerm}`;
  const siteURL = "https://avtopro.az";

  const currentUrl = window.location.href;
  // const productSchema = {
  //   "@context": "https://schema.org",
  //   "@type": "Product",
  //   "description": `${data?.data?.product_name}+" satisi avtopro.az'da"`,
  //   "name": `${data?.data?.product_name}`,
  //   "image": `${image}`,
  //   "offers": {
  //     "@type": "Offer",
  //     "availability": "1",
  //     "price": `${data?.data?.price}`,
  //     "priceCurrency": "AZN"
  //   },
  //   "seller": {
  //     "@type": "Organization",
  //     "name": `${shopName}`,
  //     "url": `${siteURL + goShop}`,
  //     "logo": `${shopLogo}`,
  //     "description": `${shopName}+" satisi avtopro.az'da"`
  //   }
  // };

  const [activeTab, setActiveTab] = useState("description");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const {
    wishListItem,
    setWishListItem,
    getAllWishLists,
    setWishLists,
    wishLists,
  } = useContext(AuthContext);
  const { setSelectedItems } = props;
  const [isAdded, setIsAdded] = useState(false);

  const addToWishlistAndRemove = async (sendData) => {
    try {
      const isItemInWishList = wishListItem?.some(
        (item) => item === sendData?.id
      );
      if (!isItemInWishList) {
        setWishListItem([...wishListItem, sendData?.id]);
        setWishLists([...wishLists, sendData]);
      }
      await postWishLists(sendData);

      if (typeof setSelectedItems === "function") {
        setSelectedItems((prevItems) => [...prevItems, sendData]);
      }
      setIsAdded(true);
      toast.success("Məhsul seçilmişlərə əlavə edildi.");
    } catch (error) {
      console.error("Wishlist səhvi:", error);
      toast.error("Seçilmişlərə əlavə edilərkən xəta baş verdi.");
    } finally {
      getAllWishLists();
    }
  };

  const handleRemoveFromWishList2 = async (sendData) => {
    try {
      console.log("Silinəcək məhsul:", sendData);

      const updatedWishItems = wishLists.filter(
        (wishItem) => wishItem.id !== sendData.product_id
      );
      setWishLists(updatedWishItems);
      await deleteWishlistsItem(sendData.product_id);

      toast.info("Məhsul seçilmişlərdən silindi.");
    } catch (error) {
      console.error("Seçilmişlərdən silinərkən xəta baş verdi:", error);
      toast.error("Seçilmişlərdən silərkən xəta baş verdi.");
    } finally {
      getAllWishLists();
    }
  };

  return (
    <>
      <Meta
        title={title}
        keywords={keywords}
        description={description}
        image={image}
        data={data}
        shopName={shopName}
        siteURL={siteURL}
        goShop={goShop}
        currentUrl={currentUrl}
        shopLogo={shopLogo}
        isDetailPage={true}
      />
      <Menu />
      <Breadcrumb page={page} />
      <section className={`container`}>
        <div className={`${classes.detail__container}`}>
          <div className={`${classes.detail_left}`}>
            <div className={`${classes.detail_itm_content}`}>
              <div className={`${classes.deail_slide_icons}`}>
                <DetailSlider data={data?.data} />
                <div className={`${classes.social_items}`}>
                  <Link
                    to={urlWp}
                    target="_blank"
                    className={`${classes.btn_social_icon} ${classes.btn_fb}`}
                  ></Link>
                  <Link
                    to={urlWp}
                    target="_blank"
                    className={`${classes.btn_social_icon} ${classes.btn_insta}`}
                  ></Link>
                  <Link
                    to={urlWp}
                    target="_blank"
                    className={`${classes.btn_social_icon} ${classes.btn_lnk}`}
                  ></Link>
                  <Link
                    to={urlWp}
                    target="_blank"
                    className={`${classes.btn_social_icon} ${classes.btn_wp}`}
                  ></Link>
                </div>
              </div>
              <div className={`${classes.deail_slide_icons_mob}`}>
                <DetailSliderMobil data={data?.data} />
                <div className={`${classes.social_items}`}>
                  <Link
                    to={urlWp}
                    target="_blank"
                    className={`${classes.btn_social_icon} ${classes.btn_fb}`}
                  ></Link>
                  <Link
                    to={urlWp}
                    target="_blank"
                    className={`${classes.btn_social_icon} ${classes.btn_insta}`}
                  ></Link>
                  <Link
                    to={urlWp}
                    target="_blank"
                    className={`${classes.btn_social_icon} ${classes.btn_lnk}`}
                  ></Link>
                  <Link
                    to={urlWp}
                    target="_blank"
                    className={`${classes.btn_social_icon} ${classes.btn_wp}`}
                  ></Link>
                </div>
              </div>

              <div className={classes.detailInfo}>
                <div className={classes.detialText}>
                  <div>
                    <h3>
                      <div>
                        {data?.data?.product_name}
                        <div className={`${classes.rating_item}`}>
                          {/* star_1 */}
                          <div
                            className={`${classes.star_item} ${classes.star_0}`}
                          >
                            <div className={`${classes.star_svg}`}></div>
                            <div className={`${classes.star_svg}`}></div>
                            <div className={`${classes.star_svg}`}></div>
                            <div className={`${classes.star_svg}`}></div>
                            <div className={`${classes.star_svg}`}></div>
                          </div>
                          <div className={`${classes.star_item_title}`}>
                            Reyting yoxdur
                          </div>
                        </div>
                      </div>

                      {data?.data?.price !== 0.0 && (
                        <p className={classes.detial_p}>
                          <strong className={classes.price}>{priceNew}</strong>
                          <b
                            className={`${classes.discount} ${classes.discountDemo}`}
                          >
                            {priceOld}
                          </b>
                        </p>
                      )}
                    </h3>
                    {/* {contentBrandName ? <p>{contentBrandName}</p> : ""} */}

                    <div
                      dangerouslySetInnerHTML={{
                        __html: contentBrandName ? `${contentBrandName}` : "",
                      }}
                    ></div>
                  </div>
                  <div className={classes.d_flex}>
                    <h3>
                      <strong className={`${classes.price}`}>
                        {priceNew}{" "}
                      </strong>
                      <b className={classes.discount}>{priceOld}</b>
                    </h3>
                  </div>
                  <div className={classes.oem__date}>
                    <div className={classes.detail_flex}>
                      <p>
                        OEM:{" "}
                        <strong>
                          {data?.data?.product_numbers[0]?.part_number}
                        </strong>
                      </p>
                      <p>
                        Məhsulun nömrəsi: <strong>{data?.data?.id}</strong>
                      </p>
                    </div>
                    <div className={classes.detail_flex2}>
                      <p>
                        Yerləşmə tarixi: <strong>{formattedDate}</strong>
                      </p>
                      <p>
                        Baxış sayı: <strong> {data?.data?.views}</strong>
                      </p>
                    </div>
                  </div>

                  <div className={`${classes.product_selected_row}`}>
                    <div className={`${classes.rating_item}`}>
                      {/* star_1 */}
                      <div className={`${classes.star_item} ${classes.star_0}`}>
                        <div className={`${classes.star_svg}`}></div>
                        <div className={`${classes.star_svg}`}></div>
                        <div className={`${classes.star_svg}`}></div>
                        <div className={`${classes.star_svg}`}></div>
                        <div className={`${classes.star_svg}`}></div>
                      </div>
                      <div className={`${classes.star_item_title}`}>
                        Reyting yoxdur
                      </div>
                    </div>
                    <div
                      onClick={async () => {
                        if (isAdded) {
                          await handleRemoveFromWishList2(sendData);
                          setIsAdded(false); // Məhsul silindikdən sonra yenilə
                        } else {
                          await addToWishlistAndRemove(sendData);
                          setIsAdded(true); // Məhsul əlavə edildikdən sonra yenilə
                        }
                      }}
                      className={`${classes.product_selected}`}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FaHeart
                        style={{
                          color: isAdded ? "red" : "gray",
                          marginRight: "8px",
                          fontSize: "23px",
                        }}
                      />
                      {isAdded ? "Seçilmişlərdən sil" : "Seçilmişlərə əlavə et"}
                    </div>
                  </div>
                </div>
                <Detailtable data={data?.data} />
              </div>
            </div>

            <div className={`${classes.detailMap} ${classes.detailMap_grid}`}>
              <div className={`${classes.detail_left}`}>
                {/* <p><Link to={`tel:+994 ${phone1}`}> <BsTelephoneFill /> {phone1} {phone2 && "/"} {phone2}</Link></p> */}
                <div className={`btnFlex btnFlexTop ${classes.btnFlexTop}`}>
                  <p>
                    <Link style={{ alignItems: "center", lineHeight: "12px" }}>
                      {LocationIcon}
                      {adress}
                    </Link>
                  </p>
                  {/* <Link className={classes.showMap}> Show on map</Link> */}
                </div>
                <div className={`map-wrap ${classes.map_wrap}`}>
                  <div ref={mapContainer} className={`map ${classes.map}`} />
                </div>
              </div>
              <div className={`${classes.detail_left}`}>
                <div className={classes.buttons}>
                  {!basket && (
                    <button
                      className={`btn btn__primary ${classes.btn__common_call} ${classes.green_btn} `}
                      disabled={addToCart}
                      onClick={handleProduct}
                    >
                      {addToCart
                        ? "Əlavə edildi"
                        : loading
                        ? "Səbətə əlavə et..."
                        : "Səbətə əlavə et"}
                    </button>
                  )}
                  {basket && (
                    <button className={`btn btn__primary`} disabled={true}>
                      Əlavə edilib
                    </button>
                  )}

                  <Link
                    className={`btn  ${classes.btn_call} ${classes.btn__common_call}`}
                    disabled={addToCart}
                    onClick={handleOrderProduct}
                  >
                    Kreditlə al
                  </Link>

                  {/* <button className={`btn  ${classes.btn_call} ${classes.btn__common_call}`} onClick={openModal}>Zəng et</button>
                <Link to={urlWp} target='_blank' className={`btn  ${classes.btn_call} ${classes.btn__common_call}`}>Whatsapp</Link> */}
                </div>
                <div className={`${classes.detailImage}`}>
                  <img src={detailImage} alt="Detail" />
                </div>
              </div>

              {/* <div className='btnFlex btnFlexTop'>
            <p> <Link onClick={() => setHeart(!heart)} style={{alignItems:"center"}}><span><FaHeart color={heart ? '#CF3434' : " #D9D9D9"} /></span> Seçilmişlərə əlavə et</Link></p>
            <Link className={classes.complaint}><CgDanger /> Şikayət et</Link>
          </div> */}
            </div>

            <div className={`${classes.detail_technics}`}>
              <div className={`${classes.detail_technics_head}`}>
                <div className={`${classes.detail_technics_title}`}>
                  Texniki göstəricilərə və təsvirə baxmaq
                </div>

                <div className={`${classes.tec_tab_container}`}>
                  <ul className={`${classes.tec_tab_btn}`}>
                    <li
                      className={`${
                        activeTab === "description" ? classes.active_tab : ""
                      }`}
                      onClick={() => handleTabClick("description")}
                    >
                      Ümumi təsvir
                    </li>
                    <li
                      className={`${
                        activeTab === "features" ? classes.active_tab : ""
                      }`}
                      onClick={() => handleTabClick("features")}
                    >
                      Xüsusiyyətləri
                    </li>
                    <li
                      className={`${
                        activeTab === "reviews" ? classes.active_tab : ""
                      }`}
                      onClick={() => handleTabClick("reviews")}
                    >
                      Məhsul haqqında rəylər və reytinq
                    </li>
                    <li
                      className={`${
                        activeTab === "warranty" ? classes.active_tab : ""
                      }`}
                      onClick={() => handleTabClick("warranty")}
                    >
                      Zəmanət
                    </li>
                    <li
                      className={`${
                        activeTab === "returns" ? classes.active_tab : ""
                      }`}
                      onClick={() => handleTabClick("returns")}
                    >
                      Qaytarılma
                    </li>
                  </ul>
                  <div className={`${classes.tec_body}`}>
                    {/* Ümumi təsvir məlumatları */}
                    {activeTab === "description" && (
                      <>
                        <div className={`${classes.tec_body_items}`}>
                          <div className={`${classes.tec_body_title}`}>
                            {data?.data?.product_name} təsviri
                          </div>
                          <div className={`${classes.tec_body_info}`}>
                            Bu məhsul üçün heç bir təsvir yoxdur
                          </div>
                        </div>

                        <div className={`${classes.tec_body_items}`}>
                          <div className={`${classes.tec_body_title}`}>
                            {data?.data?.product_name} xüsusiyyətləri
                          </div>
                          <div className={`${classes.tec_body_info}`}>
                            <div className={`${classes.tec_rows}`}>
                              <div className={`${classes.tec_rows_name}`}>
                                Avtomobil markası:{" "}
                              </div>
                              <div className={`${classes.tec_rows_val}`}>
                                <b>
                                  {data?.data?.products_car_info[0]?.car_make
                                    ?.name ?? ""}
                                </b>
                              </div>
                            </div>
                            <div className={`${classes.tec_rows}`}>
                              <div className={`${classes.tec_rows_name}`}>
                                Avtomobil modeli:{" "}
                              </div>
                              <div className={`${classes.tec_rows_val}`}>
                                {/* <b>{data?.data?.products_car_info[0].car_model?.name}</b> */}
                                <b>
                                  {data?.data?.products_car_info[0]?.car_model
                                    ?.name ?? ""}
                                </b>
                              </div>
                            </div>
                            <div className={`${classes.tec_rows}`}>
                              <div className={`${classes.tec_rows_name}`}>
                                Ehtiyat hissəsinin növü:{" "}
                              </div>
                              <div className={`${classes.tec_rows_val}`}>
                                <b>{data?.data?.category?.category_name}</b>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className={`${classes.tec_body_items}`}>
                          <div className={`${classes.tec_body_title}`}>
                            Məhsul haqqında rəylər və reytinq
                          </div>
                          <div className={`${classes.tec_body_info}`}>
                            <div className={`${classes.rating_item}`}>
                              <div
                                className={`${classes.star_item} ${classes.star_0}`}
                              >
                                <div className={`${classes.star_svg}`}></div>
                                <div className={`${classes.star_svg}`}></div>
                                <div className={`${classes.star_svg}`}></div>
                                <div className={`${classes.star_svg}`}></div>
                                <div className={`${classes.star_svg}`}></div>
                              </div>
                              <div className={`${classes.star_item_title}`}>
                                Reyting yoxdur
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className={`${classes.tec_body_items}`}>
                          <div className={`${classes.tec_body_title}`}>
                            Zəmanət
                          </div>
                          <div className={`${classes.tec_body_info}`}>
                            Zəmanət bilgisi burada olacaq.
                          </div>
                        </div>

                        <div className={`${classes.tec_body_items}`}>
                          <div className={`${classes.tec_body_title}`}>
                            Qaytarılma
                          </div>
                          <div className={`${classes.tec_body_info}`}>
                            Qaytarılma bilgisi burada olacaq.
                          </div>
                        </div>
                      </>
                    )}

                    {/* Xüsusiyyətləri məlumatları */}
                    {activeTab === "features" && (
                      <div className={`${classes.tec_body_items}`}>
                        <div className={`${classes.tec_body_title}`}>
                          {data?.data?.product_name} xüsusiyyətləri
                        </div>
                        <div className={`${classes.tec_body_info}`}>
                          <div className={`${classes.tec_rows}`}>
                            <div className={`${classes.tec_rows_name}`}>
                              Avtomobil markası:{" "}
                            </div>
                            <div className={`${classes.tec_rows_val}`}>
                              <b>
                                {data?.data?.products_car_info[0]?.car_make
                                  ?.name ?? ""}
                              </b>
                            </div>
                          </div>
                          <div className={`${classes.tec_rows}`}>
                            <div className={`${classes.tec_rows_name}`}>
                              Avtomobil modeli:{" "}
                            </div>
                            <div className={`${classes.tec_rows_val}`}>
                              {/* <b>{data?.data?.products_car_info[0].car_model?.name}</b> */}
                              <b>
                                {data?.data?.products_car_info[0]?.car_model
                                  ?.name ?? ""}
                              </b>
                            </div>
                          </div>
                          <div className={`${classes.tec_rows}`}>
                            <div className={`${classes.tec_rows_name}`}>
                              Ehtiyat hissəsinin növü:{" "}
                            </div>
                            <div className={`${classes.tec_rows_val}`}>
                              <b>{data?.data?.category?.category_name}</b>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Məhsul haqqında rəylər və reytinq məlumatları */}
                    {activeTab === "reviews" && (
                      <div className={`${classes.tec_body_items}`}>
                        <div className={`${classes.tec_body_title}`}>
                          Məhsul haqqında rəylər və reytinq
                        </div>
                        <div className={`${classes.tec_body_info}`}>
                          <div className={`${classes.rating_item}`}>
                            <div
                              className={`${classes.star_item} ${classes.star_0}`}
                            >
                              <div className={`${classes.star_svg}`}></div>
                              <div className={`${classes.star_svg}`}></div>
                              <div className={`${classes.star_svg}`}></div>
                              <div className={`${classes.star_svg}`}></div>
                              <div className={`${classes.star_svg}`}></div>
                            </div>
                            <div className={`${classes.star_item_title}`}>
                              Reyting yoxdur
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Zəmanət məlumatları */}
                    {activeTab === "warranty" && (
                      <div className={`${classes.tec_body_items}`}>
                        <div className={`${classes.tec_body_title}`}>
                          Zəmanət
                        </div>
                        <div className={`${classes.tec_body_info}`}>
                          Zəmanət məlumatları burada olacaq.
                        </div>
                      </div>
                    )}

                    {/* Qaytarılma məlumatları */}
                    {activeTab === "returns" && (
                      <div className={`${classes.tec_body_items}`}>
                        <div className={`${classes.tec_body_title}`}>
                          Qaytarılma
                        </div>
                        <div className={`${classes.tec_body_info}`}>
                          Qaytarılma məlumatları burada olacaq.
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={`${classes.detail_technics_body}`}></div>
            </div>

            <ModalCall
              phone1={phone1}
              phone2={phone2}
              isModalOpenCall={isModalOpenCall}
              setIsModalOpenCall={setIsModalOpenCall}
            />
          </div>

          <div className={`${classes.detail_right}`}>
            <div className={`${classes.detail_company_itm}`}>
              <h5 className={`${classes.detail_company_title}`}>
                Satıcı şirkət
              </h5>

              <div className={`${classes.detail_company_row}`}>
                <Link to={goShop} className={classes.logoShop}>
                  {!isFetching && <img src={shopLogo} alt={shopName} />}
                </Link>

                <div className={`${classes.call__number}`}>
                  {phone1 != null && (
                    <p>
                      <Link
                        to={`tel:+994${formattedNumber?.replace(/^0/, "")}`}
                      >
                        <BsTelephoneFill color="#639A46" />
                        {phone1}
                      </Link>
                    </p>
                  )}
                  {/* {phone2 != null && <p><Link to={`tel:+994 ${phone2}`}><BsTelephoneFill color="#639A46" />{phone2} </Link></p>} */}
                </div>
              </div>
            </div>

            <div className={`${classes.detail_company_itm}`}>
              <h5 className={`${classes.detail_company_title}`}>Çatdırılma</h5>

              <div className={`${classes.detail_transport} ${classes.courier}`}>
                <div className={`${classes.dtl_trn_title}`}>Kuryer ilə</div>
                <div className={`${classes.dtl_trn_info}`}>
                  100 ₼-dan yuxarı pulsuz
                </div>
              </div>
              <div className={`${classes.detail_transport} ${classes.stay_by}`}>
                <div className={`${classes.dtl_trn_title}`}>Yerində al</div>
                <div className={`${classes.dtl_trn_info}`}>Pulsuz</div>
              </div>
              <div
                className={`${classes.detail_transport} ${classes.express_by}`}
              >
                <div className={`${classes.dtl_trn_title}`}>
                  Ekspress çatdırılma
                </div>
                <div className={`${classes.dtl_trn_info}`}>5 ₼</div>
              </div>
              <div
                className={`${classes.detail_transport} ${classes.az_post_by}`}
              >
                <div className={`${classes.dtl_trn_title}`}>Azərpoçt</div>
                <div className={`${classes.dtl_trn_info}`}>
                  0.96 ₼-dan başlayaraq
                </div>
              </div>
            </div>

            <div className={`${classes.detail_company_itm}`}>
              <h5 className={`${classes.detail_company_title}`}>Ödəniş</h5>

              <div className={`${classes.detail_transport} ${classes.cash_by}`}>
                <div className={`${classes.dtl_trn_title}`}>Nağd</div>
                <div className={`${classes.dtl_trn_info}`}>
                  Təhvil alarkən ödəmək
                </div>
              </div>
              <div className={`${classes.detail_transport} ${classes.card_by}`}>
                <div className={`${classes.dtl_trn_title}`}>
                  Bank kartı ilə ödəmək
                </div>
                <div className={`${classes.dtl_trn_info}`}>Pulsuz</div>
              </div>
              <div
                className={`${classes.detail_transport} ${classes.credit_by}`}
              >
                <div className={`${classes.dtl_trn_title}`}>
                  Kreditlə ödəmək
                </div>
                <div className={`${classes.dtl_trn_info}`}>
                  Kapital bank-dan
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <SimilarProduct
        category_id={data?.data?.category_id}
        product_id={productId}
      />
      {/* <section className={classes.banerDetail}>
        <img src={img} alt="" />
      </section> */}

      <ToastContainer position="bottom-right" />
    </>
  );
};

export default Detail;
