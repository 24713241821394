import React, { useDeferredValue, useMemo, useState } from "react";
import { FiSearch } from "react-icons/fi";
import classes from "./shop.module.css";
// import Select from "react-select";
import Shopcard from "./shopcard";
import Breadcrumb from "../../components/ui/breadcrumb/breadcrumb";
import Menu from "../../components/ui/menu";
import { useSalons } from "../../hooks/query/shop";
import Meta from "../../components/ui/meta";
import { Link } from "react-router-dom";
import { SlArrowLeft } from "react-icons/sl";


const Shop = () => {
    const { data } = useSalons(3);
    const [name, setName] = useState("");
    const deferredName = useDeferredValue(name);

    const handleOnChange = (event) => {
        setName(event.target.value);
    };
    // console.log(data);
    // console.log("assssssssssssssdsdfasfafaf");
    const list = useMemo(() => {
        if(deferredName) {
            return data?.data?.filter((shop) => shop.name.toLowerCase().includes(deferredName.toLowerCase()));
        }

        return data?.data;
    }, [deferredName, data?.data]);

    const page = {
        pages: [
            {
                link: "/cardealers",
                content: "Avtosalonlar",
            },
        ],
    };

    console.log(list);
    console.log("list");
    console.log(data?.data);
    console.log("shop");

    const currentUrl = window.location.href;

    const title = "Avtopro - Avtosalonlar | avtopro.az";
    const keywords = "avtosalonlar, avtomobil, nəqliyyat, nəqliyyat vasitəsi, zapcast, vin, oem, avtopro, aksesuar, ağır texnika, motosiklet, təkərlər, əyləc bəndi, yağlar"
    const description = "avtosalonlar nəqliyyat vasitələrinə aid hər növ ehtiyyat hissələri və aksesuarların satışı avtopro.az'da";

    return (
        <>
            <Meta title={title} keywords={keywords} description={description} currentUrl={currentUrl} />
            <Menu />
            <Breadcrumb page={page} none='dnone' />
            <section className={`container autosalon_container ${classes.shop__container} `}>
                <Link className={`${classes.lnone}`} to="/"><SlArrowLeft color='#181818' fontSize="1.6rem" /></Link>
                <h1 className={`${classes.head}`} >
                    Avtosalonlar</h1>
                <div className={`shop__search ${classes.shop__search_custom} `}>
                    <form>
                        <input type="text" onChange={handleOnChange} placeholder="Mağaza axtar..." />
                        <button>
                            <FiSearch color="#68A834" fontSize="2rem" />
                        </button>
                    </form>
                </div>
                <div className="auto_create_link">
                    <a class="register_link" href="/tezlikle">Avtosalon qeydiyyatı <span class="rightArw"></span></a>
                </div>
                <div className={classes.shop__list}>
                    <div className="avto_second_title">
                        <h2>Bütün avtosalonlar</h2>

                        <div className="auto_create_link mobile">
                            <a class="register_link" href="/tezlikle">Avtosalon qeydiyyatı <span class="rightArw"></span></a>
                        </div>
                    </div>


                    <div className={classes.shop__list__items}>
                        {list?.map((shop) => (
                            <Shopcard key={shop.id} shop={shop} />
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
};

export default Shop;


