import { createContext, useEffect } from "react";
import React, { useState } from "react";
import { authUser } from "../services/authService";
import { getWishLists, getWishListsId } from "../services/wishListService";
import { getBaskets } from "../services/basketService";

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('token') ?? false);
  const [wishListItem, setWishListItem] = useState([]);
  const [wishLists, setWishLists] = useState([]);
  const [basketListsCount, setBasketListsCount] = useState([]);
  const [visibleMobil, setVisibleMobil] = useState(false);

  const getUser = async () => {
    if (isAuthenticated) {
      try {
        const response = await authUser();
        setUser(response.data);
      } catch (e) {
        console.error("User authentication failed", e);
      }
    }
  };

  const getWishListIds = async () => {
    if (isAuthenticated) {
      try {
        const response = await getWishListsId();
        setWishListItem(response.data);
      } catch (e) {
        console.error("Failed to fetch wish list ids", e);
      }
    }
  };

  const getAllWishLists = async () => {
    if (isAuthenticated) {
      try {
        const response = await getWishLists();
        const data = response?.data.reverse();
        const newWishItems = data?.map((list) => ({
          category_id: list?.product?.category_id,
          cover: list?.product?.cover,
          created_at: list?.product?.created_at,
          id: list?.product?.id,
          old_price: list?.product?.old_price,
          price: list?.product?.price,
          product_name: list?.product?.product_name,
          shop_id: list?.product?.shop_id,
          shop_type: list?.product?.shop?.shop_type,
          views: list?.product?.views,
        }));
        setWishLists(newWishItems);
      } catch (error) {
        console.log("Error fetching wish lists", error);
      }
    } else {
      setWishLists([]);
    }
  };

  const getAllBasketsCount = async () => {
    if (isAuthenticated) {
      try {
        const response = await getBaskets();
        setBasketListsCount(response?.data);
      } catch (error) {
        console.log("Error fetching basket count", error);
      }
    } else {
      setBasketListsCount([]);
    }
  };

  useEffect(() => {
    getUser();
    getWishListIds();
    getAllWishLists();
    getAllBasketsCount();
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        setIsAuthenticated,
        isAuthenticated,
        getAllWishLists,
        wishListItem,
        setWishListItem,
        wishLists,
        setWishLists,
        basketListsCount,
        setBasketListsCount,
        getAllBasketsCount,
        visibleMobil,
        setVisibleMobil,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
