import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./navbar/navbar";
import Footer from "./footer/footer";
import Loading from "../components/loading/loading";

// Servisleri import edin
import { getProducts } from "../services/productService";
import { getCategory } from "../services/categoryService";
import { getCarSalonGenerations } from "../services/carGenerationService";

const Layout = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [carsData, setCartData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [productsData, categoriesData, carsData] = await Promise.all([
          getProducts(),
          getCategory(),
          getCarSalonGenerations(),
        ]);

        setProducts(productsData);
        setCategories(categoriesData);
        setCartData(carsData);

        setIsLoading(false);
      } catch (error) {
        console.error("API sorğusu xətası:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Navbar />
      <main className="main">
        {isLoading ? (
          <div className="loading-overlay">
            <Loading />
          </div>
        ) : (
          <Outlet />
        )}
      </main>
      <Footer />
    </>
  );
};

export default Layout;
