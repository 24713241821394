import Select from "react-select";
import classes from "./filter.module.css";
import { FiSearch } from "react-icons/fi";
import { useAllCarTypes } from "../../hooks/query/carType";
import { useCarmakes } from "../../hooks/query/carMake";
import { useCarModels } from "../../hooks/query/carModel";
import { useCarSalonGenerations } from "../../hooks/query/carGeneration";
import { useCarSalonEngines } from "../../hooks/query/carEngine";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { CustomStyles } from "../../helpers/contants/s3";


const Filter = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [carTypeId, setCarTypeId] = useState(Number(searchParams.get("car_type_id") ?? 0));
    const [carMakeId, setCarMakeId] = useState();
    const [carPropId, setCarPropId] = useState(props?.params?.shop_id);
    const [carModelId, setCarModelId] = useState(Number(searchParams.get("car_model_id") ?? 0));
    const [carGenerationId, setCarGenerationId] = useState(Number(searchParams.get("car_generation_id") ?? 0));
    const [carEngineId, setCarEngineId] = useState(Number(searchParams.get("car_engine_id") ?? 0));
    const [product_number, setProductNumber] = useState(searchParams.get("product_number") ?? "");
    const [isFixed, setIsFixed] = useState(false);
    const ref = useRef()
    const prop_shop = props?.params?.shop_id

    // console.log(props);
    // console.log("propspropspropspropsprops");
    // console.log("propspropspropspropsprops");

    const {
        data: carTypeData
    } = useAllCarTypes({ id_car_default: 1, order: "name", direction: "asc" });

    const {
        data: carMakeData,
        refetch: carMakeRefetch,
        isStale: carMakeIsStale,
    } = useCarmakes({ id_car_type: 1, order: "name", direction: "asc" });

    const {
        data: carModelData,
        refetch: carModelRefetch,
        isStale: carModelIsStale,
    } = useCarModels({ id_car_make: carMakeId, order: "name", direction: "asc" });

    // const {
    //     data: carMakeData,
    //     refetch: carMakeRefetch,
    //     isStale: carMakeIsStale,
    // } = useAllCarMakes({ shop_id: carPropId });

    // const {
    //     data: carModelData,
    //     refetch: carModelRefetch,
    //     isStale: carModelIsStale,
    // } = useCarModels({ shop_id: carPropId, make_id: carMakeId });
    const {
        data: carGenerationData,
        refetch: carGenerationRefetch,
        isStale: carGenerationIsStale,
    } = useCarSalonGenerations({ id_car_make: carMakeId, year: carGenerationId });
    const {
        data: carEngineData,
        refetch: carEngineRefetch,
        isStale: carEngineIsStale,
    } = useCarSalonEngines({ shop_id: carPropId, make_id: carMakeId, model_id: carModelId, year: carGenerationId });
    // engine_volume

    const handleCarTypeChange = (e) => {
        if(e && e.value !== undefined) {
            setCarTypeId(e.value);
        }
    };

    const handleCarMakeChange = (e) => {
        if(e && e.value !== undefined) {
            setCarMakeId(e.value);
        }
    };

    const handleCarModelChange = (e) => {
        if(e && e.value !== undefined) {
            setCarModelId(e.value);
        }
        // console.log(carModelId);
        // console.log("carModelId");
    };

    const handleCargenerationChange = (e) => {
        if(e && e.value !== undefined) {
            setCarGenerationId(e.value);
        }
    };
    const handleCarengineChange = (e) => {
        if(e && e.value !== undefined) {
            // setCarGenerationId(e.value);
            setCarEngineId(e.value);
        }
    };

    const handleClear = () => {
        setCarMakeId(0);
        setCarModelId(0);
        setCarGenerationId(0);
        handleCarMakeChange();
        handleCarModelChange();
        handleCargenerationChange();

        resetSiblingValues('.css-1dimb5e-singleValue', 3);

    };



    useEffect(() => {
        setCarMakeId(props?.params?.carMakeId);
        // if(carMakeIsStale && carTypeId !== 0) {
        // carMakeRefetch()
        // } else {
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    useEffect(() => {
        if(carModelIsStale) {
            carModelRefetch()
        } else {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carMakeId]);

    useEffect(() => {
        if(carGenerationIsStale) {
            carGenerationRefetch();
        } else {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carModelId]);

    useEffect(() => {
        // if(carEngineIsStale) {
        carEngineRefetch();
        // } else {
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carGenerationId]);


    const resetSiblingValues = (className, index) => {
        // try {

        const resultDivs = document.querySelectorAll(className);
        resultDivs.forEach((resultDiv, i) => {
            // if (i > index - 1 && i >= index) {
            resultDiv[i].innerHTML = '';
            // }
        });
        // } catch(error) {
        //     // Boş bırakabilirsiniz veya sadece bir hata mesajı yazdırabilirsiniz
        //     // console.error("Hata oluştu:", error);
        // }
    };

    // const resetSiblingValues = (className, index) => {
    //     const resultDivs = document.querySelectorAll(className);
    //     for(let i = index; i < resultDivs.length; i++) {
    //         resultDivs[i].innerText = '';
    //     }
    // };

    const carTypeValues = carTypeData?.data?.map((item) => ({
        value: item.id,
        label: item.name,
    }));

    const carMakeValues = carMakeData?.data?.map((item) => ({
        value: item.id,
        label: item.name,
    }));
    const carModelsValues = carModelData?.data?.map((item) => ({
        value: item.id,
        label: item.name
    }));


    // console.log(carModelsValues);
    // console.log("carModelData?.data");

    // const carGenerationsValues = carGenerationData?.data?.map((item) => ({
    //     value: item.year,
    //     label: item.year
    // }));

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 1904 + 1 }, (v, k) => 1904 + k).reverse();

    const carGenerationsValues = years.map(year => ({
        value: year,
        label: year
    }));
    // const carGenerationsValues = [...new Set(carGenerationData?.data?.map(item => item.year))]
    //     .sort((a, b) => b - a)
    //     .map(year => ({
    //         value: year,
    //         label: year
    //     }));


    const carEnginesValues = carEngineData?.data?.map((item) => ({
        value: item.id,
        label: item.name
    }));
    // const carEnginesValues = [...new Set(carEngineData?.data?.map(item => item.year))]
    //     .sort((a, b) => b - a)
    //     .map(year => ({
    //         value: year,
    //         label: year
    //     }));


    const handleSubmitCarInfo = (e) => {
        e.preventDefault();
        const queryParams = Object.fromEntries(searchParams.entries());

        // queryParams.car_type_id = carTypeId;
        // queryParams.car_make_id = carMakeId;
        queryParams.car_model_id = carModelId;
        queryParams.car_generation_id = carGenerationId;
        queryParams.car_engine_id = carEngineId;
        delete queryParams.product_number;

        const updatedSearchParams = new URLSearchParams(queryParams);
        setSearchParams(updatedSearchParams);

        if(location.pathname === "/") {
            navigate(`/autosalons?${updatedSearchParams.toString()}`);
        } else {
            navigate(`${location.pathname}?${updatedSearchParams.toString()}`);
        }


    };

    const handleSubmitProducNumber = (e) => {
        e.preventDefault();
        const queryParams = Object.fromEntries(searchParams.entries());

        // delete queryParams.car_type_id;
        // delete queryParams.car_make_id;
        delete queryParams.car_model_id;
        delete queryParams.car_generation_id;
        delete queryParams.car_engine_id;
        delete queryParams.shop_type;
        delete queryParams.shop_id;
        queryParams.product_number = product_number;

        const updatedSearchParams = new URLSearchParams(queryParams);
        setSearchParams(updatedSearchParams);

        if(location.pathname === "/") {
            navigate(`/autosalon?${updatedSearchParams.toString()}`);
        } else {
            navigate(`/autosalon?${updatedSearchParams.toString()}`);
        }
    };


    useEffect(() => {
        const handleScroll = () => {
            const currentFilter = ref.current.offsetTop;
            if(window.scrollY < 150) {
                setIsFixed(false);
            } else if(window.scrollY >= currentFilter) {
                setIsFixed(true);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);



    return (
        <section className={`${classes.filterFixed} ${isFixed ? classes.stickyFilter : props.home ? classes.absoluteFilter : ''}`} ref={ref}>
            <div className={`container ${classes.filter__container}`}>
                {/* <div className="custom-single-value">Sec----:::  </div> */}
                <form onSubmit={handleSubmitCarInfo}>
                    {/* <Select
                        className={classes.selectItem}
                        onChange={handleCarTypeChange}
                        placeholder={"Növ"}
                        options={carTypeValues}
                        value={carTypeValues?.find((option) => option.value === carTypeId)}
                        styles={CustomStyles}
                    /> */}
                    {/* <Select
                        className={classes.selectItem}
                        onChange={handleCarMakeChange}
                        placeholder={"Marka"}
                        options={carMakeValues}
                        value={carMakeValues?.find((option) => option.value === carMakeId)}
                        styles={CustomStyles}
                    /> */}
                    <Select
                        className={classes.selectItem}
                        onChange={handleCarModelChange}
                        placeholder={"Model"}
                        options={carModelsValues}
                        value={carModelsValues?.find((option) => option.value === carModelId)}
                        styles={CustomStyles}
                    />
                    <Select
                        className={classes.selectItem}
                        onChange={handleCargenerationChange}
                        placeholder={"Istehsal tarixi"}
                        options={carGenerationsValues}
                        value={carGenerationsValues?.find((option) => option.value === carGenerationId)}
                        styles={CustomStyles}
                    />
                    <Select
                        className={classes.selectItem}
                        onChange={handleCarengineChange}
                        placeholder={"Ban növü"}
                        options={carEnginesValues}
                        value={carEnginesValues?.find((option) => option.value === carEngineId)}
                        styles={CustomStyles}
                    />
                    <button className={classes.btn__filter__search}>
                        <FiSearch fontSize="2rem" />
                    </button>
                </form>
            </div>
        </section>
    );
};

export default Filter;

