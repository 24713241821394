import classes from "./detail.module.css";
import DetailSlider from "./detailslider";
import Detailtable from "./detailtable";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import Breadcrumb from "../../components/ui/breadcrumb/breadcrumb";
import Menu from "../../components/ui/menu/index";
import { useSalon } from "../../hooks/query/products";
import SimilarProduct from "./similarProduct";
import SimilarItem from "./similarItem";
import Meta from "../../components/ui/meta";
// import img from "../../assets/images/kaptal.png"
import DetailSliderMobil from "./detailSliderMobil";
import { getBaskets, postProduct } from "../../services/basketService";
import { ToastContainer, toast } from "react-toastify";
import "./map.css";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import { useEffect, useState, useRef, useContext } from "react";
// import { ModalNav } from "../../layouts/navbar/modalNavbar/modalNav";
import { ModalCall } from "./modalcall";
import {
  HomeIcon,
  LocationIcon,
  EditProductIcon,
  AddFavtIcon,
  DelProductIcon,
  PremiumProductIcon,
  ReportProductIcon,
  ForwardProductIcon,
} from "../../assets/icons/Icons";
import { AuthContext } from "../../context/AuthContext";
import { MenuContext } from "../../context/MenuContext";
import { baseURL } from "../../helpers/api/request";
import { FaHeart } from "react-icons/fa";
// import { FaHeart } from "react-icons/fa";
// import {CgDanger} from "react-icons/cg";
// import {EyeCardIcon} from "../../assets/icons/Icons"
import {
  ShopSocialFb,
  ShopSocialInsta,
  ShopSocialLinkedn,
  ShopSocialWp,
  ShopTel,
  ShopLoc,
  ShopWeb,
  ShopWork,
} from "../../../src/assets/icons/Icons";
import {
  deleteWishlistsItem,
  postWishLists,
} from "../../services/wishListService";

const Detail = (props) => {
  const navigate = useNavigate();
  const { isAuthenticated, basketListsCount, setBasketListsCount } =
    useContext(AuthContext);
  const { setShowBasket } = useContext(MenuContext);
  const { productId } = useParams();
  const urlArray = productId.split("-");
  const id = urlArray[urlArray.length - 1];
  const { data, isFetching } = useSalon(id);
  const [addToCart, setAddToCart] = useState(false);
  const [loading, setLoading] = useState(false);
  const [basket, setBasket] = useState(false);
  const [basketLists, setBasketLists] = useState([]);
  const [isModalOpenCall, setIsModalOpenCall] = useState(false);
  // const mapContainer = useRef(null);
  const mapLocation = useRef(null);
  const [lng] = useState(49.94901057127427);
  const [lat] = useState(40.41384243911918);
  const [zoom] = useState(9);
  const [API_KEY] = useState("j4WWgdwepZdF7D5WbiTh");
  const [isShow, setIsShow] = useState(false);

  const handleToggle = () => {
    setIsShow(!isShow);
  };
  // const openModal = () => {
  //   setIsModalOpenCall(true);
  // };

  // const sendData = {
  //   product_id: data?.data?.id,
  //   count: 1
  // }

  // const [locations, setLocations] = useState([]);

  // useEffect(() => {

  //   if(!isFetching) {
  //     const shopMap = data?.data?.shop?.shop_information?.map((item) => {
  //       return {
  //         lng: item?.longitude,
  //         lat: item?.latitude
  //       };
  //     });

  //     setLocations((p) => [...locations, shopMap].flat(1))
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isFetching]);

  // // useEffect(() => {
  // //   if(mapLocation.current) return; //stops map from intializing more than once
  // //   mapLocation.current = new maplibregl.Map({
  // //     container: mapContainer.current,
  // //     style: `https://api.maptiler.com/maps/streets-v2/style.json?key=${API_KEY}`,
  // //     center: [lng, lat],
  // //     zoom: zoom
  // //   });
  // //   // map.current.addControl(new maplibregl.NavigationControl(), 'top-right');

  // // });

  // // locations?.map((location) => {
  // //   return new maplibregl.Marker({ color: "#FF0000" })
  // //     .setLngLat([location.lng, location.lat])
  // //     .addTo(mapLocation.current);
  // // });

  // // locations?.map((location) => {
  // //   if(locations[0] === undefined) {
  // //     return navigate('/not-found')
  // //   }
  // //   if(location.lng && location.lat) {
  // //     return new maplibregl.Marker({ color: "#FF0000" })
  // //       .setLngLat([location.lng, location.lat])
  // //       .addTo(mapLocation.current);
  // //   }
  // // });

  // // console.log("locations");
  // // console.log(locations);
  // // console.log("locations");

  // const sendDataSmall = {
  //   product_id: data?.data?.id,
  //   count: 1,
  //   product: {
  //     cover: `${data?.data?.images[0]?.directory}/${data?.data?.images[0]?.file_name}`,
  //     price: data?.data?.price,
  //     product_name: data?.data?.product_name
  //   },
  //   id: data?.data?.id
  // }

  // const handleProduct = async () => {
  //   setLoading(true)
  //   try {
  //     const response = await postProduct(sendData)
  //     if(response.success) {
  //       toast.success('Səbətə əlavə edildi');
  //     }
  //     setBasketListsCount([...basketListsCount, sendDataSmall])
  //     setAddToCart(true)
  //     setShowBasket(true)
  //   } catch(error) {
  //     console.log(error)
  //   } finally {
  //     setLoading(false)
  //   }

  // }

  // const handleOrderProduct = async () => {

  //   try {
  //     const response = await postProduct(sendData)
  //     setBasketListsCount([...basketListsCount, sendData])
  //     toast.success('Səbətə əlavə edildi');
  //     if(response.success) {
  //       navigate("/cart/cartlist")
  //     }
  //     setAddToCart(true)
  //   } catch(error) {
  //     console.log(error)
  //   }

  // }

  // const getAllBaskets = async () => {
  //   try {
  //     const response = await getBaskets()
  //     const data = await response?.data;
  //     setBasketLists(data)
  //   } catch(error) {
  //     console.log(error)
  //   }
  // }

  // useEffect(() => {
  //   if(isAuthenticated) {
  //     getAllBaskets()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // useEffect(() => {
  //   const checkId = basketLists?.some((basket) => basket.product_id === Number(id))
  //   setBasket(checkId)
  // }, [basketLists, id])

  let dateString = data?.data?.created_at;
  let formattedDate = "";

  if (dateString) {
    let date = new Date(dateString);
    formattedDate = dateString;
    // if(!isNaN(date.getTime())) {
    //   const parts = date.toISOString().substring(0, 10).split(".");
    //   formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    // }
  }

  // const adress = data?.data?.shop?.shop_information[0]?.address
  // const phone1 = data?.data?.shop?.shop_information[0]?.phone1
  // const phone2 = data?.data?.shop?.shop_information[0]?.phone2

  const price1 = data?.data?.price ?? null; // Eğer price null/undefined ise null döner
  const price2 = data?.data?.old_price ?? null; // Eğer old_price null/undefined ise null döner

  // // const parseData = new DOMParser().parseFromString(data?.data?.information, 'text/html');
  // // const textContent = parseData.documentElement.textContent;
  // // const brandName = textContent.replace(/(.*)&nbsp;&nbsp;.*/, '$1');

  const parseData = data?.data.description;
  const textContent = parseData;
  const brandName = textContent;

  let contentBrandName;
  if (
    brandName === "undefined" ||
    brandName === undefined ||
    brandName === null ||
    brandName === "null"
  ) {
    contentBrandName = "";
  } else {
    contentBrandName = brandName;
  }

  let priceNew;
  if (
    price1 === "0.00" ||
    price1 === 0.0 ||
    price1 === null ||
    price1 === "null" ||
    typeof price1 === "undefined" ||
    price1 === undefined
  ) {
    priceNew = "";
  } else {
    priceNew = `${price1} ₼`;
  }

  let priceOld;
  if (
    price2 === "0.00" ||
    price2 === 0.0 ||
    price2 === null ||
    price2 === "null" ||
    typeof price2 === "undefined" ||
    price2 === undefined
  ) {
    priceOld = ``;
  } else {
    priceOld = `${price2} ₼`;
  }

  // let goShop;
  // if(data?.data?.shop?.shop_type === 2) {
  //   goShop = `/shops/${data?.data?.shop_id}?shop_type=2&shop_id=${data?.data?.shop_id}`
  // } else if(data?.data?.shop?.shop_type === 1) {
  //   goShop = `/dealers/${data?.data?.shop_id}?shop_type=1&shop_id=${data?.data?.shop_id}`
  // }

  const pageLink =
    data?.data.breadcrumb && data?.data.breadcrumb.length > 0
      ? data?.data.breadcrumb.map((bread) => {
          return {
            link: `/autosalon/${data?.data?.shop_id}?shop_type=3&shop_id=${data?.data?.shop_id}`,
            content: bread?.category_name + " avtosalonu",
          };
        })
      : [];

  const image_url = `https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/storage/`;
  const seo_image_url = `https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/`;

  const page = {
    pages: [
      {
        link: "/autosalon",
        content: " Bütün məhsullar",
      },
      ...pageLink,
      {
        link: `/autosalon?category_id=${data?.data?.category?.id ?? ""}`,
        content: data?.data?.model?.name ?? "",
      },
    ],
  };

  const shopLogo = image_url + data?.data.shop.cover;
  const shopName = `${data?.data.shop.name}`;
  const created_id = data?.data.id;
  // const created_date = new Date(data?.data?.created_at);
  // // const created_at = new Intl.DateTimeFormat('tr-AZ', {
  // //   day: '2-digit',
  // //   month: '2-digit',
  // //   year: 'numeric'
  // // }).format(created_date);

  // // const formattedDate = date.toLocaleString('en-US', {
  // //   year: 'numeric',
  // //   month: '2-digit',
  // //   day: '2-digit',
  // //   hour: '2-digit',
  // //   minute: '2-digit',
  // //   hour12: false
  // // });
  // // const year = formattedDate.split('/')[2];
  // // const month = formattedDate.split('/')[0];
  // // const day = formattedDate.split('/')[1];
  let product_name;
  product_name = `${data?.data.make.name} ${data?.data.model.name}`;

  const title = `${product_name} | avtopro.az`;
  // // const keywords = `${data?.data?.product_name ? data?.data?.product_name : ''}`
  const keywords =
    "oem, məhsullar, vin, ehtiyyat hissələri, avtomobil, nəqliyyat vasitəsi, zapcast,  avtopro, aksesuar, ağır texnika, motosiklet, təkərlər, əyləc bəndi, yağlar";
  const description = `${
    (product_name ? product_name : "") +
    " - nəqliyyat vasitələrinə aid hər növ ehtiyyat hissələri və aksesuarların satışı | avtopro.az"
  }`;
  const image = `${
    data?.data.shop.cover
      ? seo_image_url + data?.data.shop.cover + "/" + data?.data.shop.cover
      : ""
  }`;

  // const searchTerm = 'Avtoprodan yeni müştəri';
  // const encodedSearchTerm = encodeURIComponent(searchTerm);
  // const phoneClear = phone1;
  // let reversedString = "";
  // let resultPhone = "";

  // if(phoneClear == "" || phoneClear == null || phoneClear == undefined) {
  // } else {
  //   reversedString = phoneClear.replace(/\s|\(|\)/g, '').split('').reverse().slice(0, 9).reverse().join('');
  //   resultPhone = "994" + reversedString;
  // }

  // const urlWp = `https://wa.me/${resultPhone}/?text=${encodedSearchTerm}`;
  const siteURL = "https://avtopro.az";

  const currentUrl = window.location.href;

  // console.log(data?.data);
  // console.log(data?.data?.shop?.shop_information[0]?.phone1);

  const {
    wishListItem,
    setWishListItem,
    getAllWishLists,
    setWishLists,
    wishLists,
  } = useContext(AuthContext);
  const { setSelectedItems } = props;
  const [isAdded, setIsAdded] = useState(false);

  // const addToWishlistAndRemove = async (data) => {
  //   try {
  //     const shopId = data?.data?.shop?.id; // shop.id sahəsi əldə edilir
  //     if (!shopId) {
  //       throw new Error("shop_id mütləqdir.");
  //     }

  //     const isItemInWishList = wishListItem?.some((item) => item === shopId);

  //     if (!isItemInWishList) {
  //       setWishListItem([...wishListItem, shopId]);
  //       setWishLists([...wishLists, data]);
  //     }

  //     await postWishLists({
  //       shop_id: shopId, // shop_id backendə göndərilir
  //     });

  //     toast.success("Məhsul seçilmişlərə əlavə edildi.");
  //   } catch (error) {
  //     console.error("Xəta:", error.response?.data || error.message);
  //     toast.error(
  //       error.response?.data?.message || "Seçilmişlərə əlavə edilərkən xəta baş verdi."
  //     );
  //   } finally {
  //     getAllWishLists();
  //   }
  // };

  
  const addToWishlistAndRemove = async (data) => {
    try {
      const isItemInWishList = wishListItem?.some(
        (item) => item === data?.data?.shop_id
      );
      if (!isItemInWishList) {
        setWishListItem([...wishListItem,data?.data?.shop_id]);
        setWishLists([...wishLists, data]);
      }
      await postWishLists(data);

      if (typeof setSelectedItems === "function") {
        setSelectedItems((prevItems) => [...prevItems, data]);
      }
      setIsAdded(true);
      toast.success("Məhsul seçilmişlərə əlavə edildi.");
    } catch (error) {
      console.error("Wishlist səhvi:", error);
      toast.error("Seçilmişlərə əlavə edilərkən xəta baş verdi.");
    } finally {
      getAllWishLists();
    }
  };
  console.log(data)

  const handleRemoveFromWishList2 = async (data) => {
    try {
      if (!data?.product_id) {
        throw new Error("Silinmək üçün product_id mövcud deyil.");
      }

      console.log("Silinəcək məhsul:", data);

      const updatedWishItems = wishLists.filter(
        (wishItem) => wishItem.id !== data.data.id
      );

      setWishLists(updatedWishItems);

      await deleteWishlistsItem(data.data.id);

      toast.info("Məhsul seçilmişlərdən silindi.");
    } catch (error) {
      console.error(
        "Seçilmişlərdən silinərkən xəta baş verdi:",
        error.response?.data || error.message
      );
      toast.error(
        error.response?.data?.message ||
          "Seçilmişlərdən silərkən xəta baş verdi."
      );
    }
  };



  return (
    <>
      <Meta
        title={title}
        keywords={keywords}
        description={description}
        data={data}
        siteURL={siteURL}
        currentUrl={currentUrl}
        image={image}
        shopName={shopName}
        isDetailPage={true}
      />
      <Menu />
      <Breadcrumb page={page} />
      <section className={`container ${classes.detail__container}`}>
        <div className={`${classes.auto_detail_head} ${classes.mobile_hide}`}>
          <h3>
            <span className={`${classes.mobile_hide}`}>{product_name} </span>
          </h3>
          <div className={`${classes.auto_detail_head_btns}`}>
            <div
              onClick={async () => {
                if (isAdded) {
                  await handleRemoveFromWishList2(data);
                  setIsAdded(false);
                } else {
                  await addToWishlistAndRemove(data);
                  setIsAdded(true);
                }
              }}
              className={`${classes.product_selected}`}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FaHeart
                style={{
                  color: isAdded ? "red" : "gray",
                  marginRight: "8px",
                  fontSize: "23px",
                }}
              />
              {isAdded ? "Seçilmişlərdən sil" : "Seçilmişlərə əlavə et"}
            </div>
            <div className={`${classes.product_complain}`}>Şikayət et</div>
          </div>
        </div>

        <div className={`${classes.detail_content_sect}`}>
          {/* Slider section */}
          <div className={`${classes.detail_slide_sect}`}>
            <div className={`${classes.mobile_show_flex_item} `}>
              <Link
                to={`/autosalon/${data?.data?.shop_id}?shop_type=3&shop_id=${data?.data?.shop_id}`}
                className={`${classes.mobile_show}`}
              ></Link>
              <div className={`${classes.product_selected}`}> </div>
            </div>
            <Link
              to={`/autosalon/${data?.data?.shop_id}?shop_type=3&shop_id=${data?.data?.shop_id}`}
              className={`${classes.mobile_show_right}`}
            >
              <div className={classes.logoShop}>
                {!isFetching && <img src={shopLogo} alt={shopName} />}
                <div>
                  <span>{shopName}</span>
                  <p>Rəsmi nümayəndəsi</p>
                </div>
              </div>
            </Link>

            {/* Sliders */}
            <div>
              <DetailSlider data={data?.data} />
            </div>
            {/* <DetailSliderMobil data={data?.data} /> */}
            {/* Sliders */}

            <div className={`${classes.mobile_show_sect} `}>
              <div className={`${classes.detail_row}`}>
                <h3>
                  {" "}
                  {data?.data.price !== 0.0 && (
                    <div className={classes.detial_p}>
                      <strong className={classes.price}>{priceNew}</strong>
                      <b
                        className={`${classes.discount} ${classes.discountDemo}`}
                      >
                        {priceOld}
                      </b>
                    </div>
                  )}
                </h3>
              </div>
              <div className={`${classes.detail_row}`}></div>
            </div>

            <div className={`${classes.top_detail_row} ${classes.mobile_hide}`}>
              <div className={`${classes.top_detail}`}>
                <span>Yeniləndi:</span>
                <p>{formattedDate}</p>
              </div>
              <div className={`${classes.top_detail}`}>
                <span>Baxış sayı:</span>
                <p>{data?.data?.views}</p>
              </div>
            </div>

            {/* Tabel section */}
            <Detailtable data={data?.data} />
            {/* Tabel section */}

            <div
              className={`${classes.auto_desc_conent} ${
                isShow ? classes.show : ""
              }`}
            >
              <div
                className={`${classes.desctiption} `}
                dangerouslySetInnerHTML={{
                  __html: contentBrandName ? `${contentBrandName}` : "",
                }}
              ></div>
              <div className={`${classes.auto_desc_more_item}`}>
                <div
                  className={`${classes.auto_desc_more}`}
                  onClick={handleToggle}
                >
                  Davamını oxu
                </div>
              </div>
            </div>
          </div>
          {/* Slider section */}

          {/* Detail section */}
          <div className={classes.detailRight}>
            <div className={classes.detailInfo}>
              <div className={classes.detialText}>
                <div className={`${classes.mobile_hide}`}>
                  <div className={`${classes.detail_row}`}>
                    <div className={`${classes.sect_flex_mob}`}>
                      <h3>
                        {" "}
                        {data?.data.price !== 0.0 && (
                          <div className={classes.detial_p}>
                            <strong className={classes.price}>
                              {priceNew}
                            </strong>
                            <b
                              className={`${classes.discount} ${classes.discountDemo}`}
                            >
                              {priceOld}
                            </b>
                          </div>
                        )}
                      </h3>
                    </div>
                    <div className={`${classes.mobile_hide}`}>
                      <div className={classes.d_flex}>
                        <h3>
                          <strong className={`${classes.price}`}>
                            {priceNew}{" "}
                          </strong>
                          <b className={classes.discount}>{priceOld}</b>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className={classes.logoShop}>
                    {!isFetching && <img src={shopLogo} alt={shopName} />}
                    <div>
                      <span>{shopName}</span>
                      <p>Rəsmi nümayəndəsi</p>
                    </div>
                  </div>
                </div>

                <div
                  className={`${classes.auto_about_sect} ${classes.mobile_hide}`}
                >
                  <div className={`${classes.auto_about_title}`}>Haqqında</div>
                  <div className={`${classes.auto_about_stext}`}></div>
                </div>

                <div className={classes.mobile_hide}>
                  <div className={classes.hide_items}>
                    <div className={classes.auto_work_time}>
                      <span className={classes.auto_sp_icon}>{ShopWork}</span>
                      {data?.data?.shop?.shop_information[0]?.work_time}
                    </div>
                  </div>
                  <div className={classes.hide_items}>
                    <Link
                      to={`https://www.google.com/maps?q=${encodeURIComponent(
                        data?.data?.shop?.shop_information[0]?.address
                      )}`}
                      className={classes.auto_work_time}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className={classes.auto_sp_icon}>{ShopLoc}</span>
                      {data?.data?.shop?.shop_information[0]?.address}
                    </Link>
                  </div>
                </div>

                <div className={classes.detailMap}>
                  <div
                    className={`${classes.top_detail_row} ${classes.mobile_show_flex_item}`}
                  >
                    <div className={`${classes.top_detail}`}>
                      <span>Yeniləndi:</span>
                      <p>{formattedDate}</p>
                    </div>
                    <div className={`${classes.top_detail}`}>
                      <span>Baxış sayı:</span>
                      <p>{data?.data?.views}</p>
                    </div>
                    <div className={`${classes.top_detail}`}>
                      <span>Elan nömrəsi:</span>
                      <p>{created_id}</p>
                    </div>
                  </div>
                  <div className={classes.buttons}>
                    <Link
                      className={`btn  ${classes.btn_call} ${classes.btn__common_call}`}
                      to={`tel:+994${
                        (data &&
                          data.data &&
                          data.data.shop &&
                          data.data.shop.shop_information &&
                          data.data.shop.shop_information[0]?.phone1?.replace(
                            /^0/,
                            ""
                          )) ||
                        ""
                      }`}
                    >
                      Əlaqə nömrəsi
                    </Link>
                    <Link
                      to={`/autosalon/${data?.data?.shop_id}?shop_type=3&shop_id=${data?.data?.shop_id}`}
                      className={`btn  ${classes.btn_call} ${classes.btn__common_call}`}
                    >
                      Salona keç
                    </Link>
                    <button
                      className={`btn  ${classes.btn_call} ${classes.btn__common_call}`}
                    >
                      {ForwardProductIcon} Elanı irəli çək
                    </button>
                    <button
                      className={`btn  ${classes.btn_call} ${classes.btn__common_call}`}
                    >
                      {PremiumProductIcon} Premium et
                    </button>
                    {/* <button className={`btn  ${classes.btn_call} ${classes.btn__common_call}`}>{EditProductIcon} Elana düzəliş et</button> */}
                    {/* <button className={`btn  ${classes.btn_call} ${classes.btn__common_call}`}>{AddFavtIcon} Seçilmişlərə əlavə et</button> */}
                    {/* <button className={`btn  ${classes.btn_call} ${classes.btn__common_call}`}>{DelProductIcon} Elanı sil</button> */}
                    {/* <button className={`btn  ${classes.btn_call} ${classes.btn__common_call}`}>{ReportProductIcon} Şikayət et</button> */}
                  </div>
                </div>
                {/* <ModalCall phone1={phone1} phone2={phone2} isModalOpenCall={isModalOpenCall} setIsModalOpenCall={setIsModalOpenCall} /> */}
              </div>

              {/* <div className={`${classes.auto_desc_conent}`}>
                <div className={`${classes.desctiption} ${classes.mobile_show_sect}`} dangerouslySetInnerHTML={{ __html: contentBrandName ? `${contentBrandName}` : "" }}></div>
                <div className={`${classes.auto_desc_more_item}`}>
                  <div className={`${classes.auto_desc_more}`}>Davamını oxu</div>
                </div>
              </div> */}

              {/* <div className={`${classes.auto_desc_conent} ${isShow ? classes.show : ""}`}>
                <div className={`${classes.desctiption} ${classes.mobile_show_sect}`} dangerouslySetInnerHTML={{ __html: contentBrandName ? `${contentBrandName}` : "" }}></div>
                <div className={`${classes.auto_desc_more_item}`}>
                  <div
                    className={`${classes.auto_desc_more}`}
                    onClick={handleToggle}
                  >
                    Davamını oxu
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {/* Detail section */}
        </div>
        <div className={`${classes.top_detail_row} ${classes.top_mrg}`}>
          <div className={`${classes.btn_anc_item}`}>Düzəliş et</div>
          <div className={`${classes.btn_anc_item}`}>Elanı sil</div>
          <div
            className={`${classes.btn_anc_item} ${classes.mobile_show_flex_item}`}
          >
            Şikayət et
          </div>
          <div className={`${classes.top_detail} ${classes.mobile_hide}`}>
            <span>Elan nömrəsi:</span>
            <p>{created_id}</p>
          </div>
        </div>
      </section>

      <SimilarProduct
        category_id={data?.data?.shop_id}
        product_id={productId}
      />

      {/* <SimilarItem category_id={data?.data?.shop_id} product_id={productId} /> */}

      {/* <ToastContainer position="bottom-right" /> */}
    </>
  );
};

export default Detail;
