import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import classes from "./shop-inner.module.css"
import Shopinfo from '../shopinfo/shopinfo'
import Filter from '../../../components/ui/filter/filter'
import Mobilfilter from '../../../components/ui/mobilfilter/mobilfilter'
import Modal from '../../../modals/Modal'
import { TbClockHour4 } from "react-icons/tb"
import { TfiWorld } from "react-icons/tfi"
import { Link } from 'react-router-dom'
import { BsFillTelephoneFill, BsEyeFill, BsInfoCircle } from "react-icons/bs"
import { HiLocationMarker } from 'react-icons/hi'
import { GrYoutube } from 'react-icons/gr'
import Menu from '../../../components/ui/menu/index'
import Breadcrumb from '../../../components/ui/breadcrumb/breadcrumb'
import { useShop } from '../../../hooks/query/shop';
import { useProducts } from '../../../hooks/query/products';
import ShopProduct from '../../../components/shopProduct';
import Meta from '../../../components/ui/meta';
import ParentCategory from '../../../components/parentCategory';
import Product from "../../../components/product/product"
import { useInfiniteQuery } from '@tanstack/react-query';
import { getProducts } from '../../../services/productService';
import WheelFilter from '../../../components/wheel_filter/wheel_filter';
import MobilWheelfilter from '../../../components/mobil_wheel_filter/mobil_wheel_filter';


const Shopinner = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { shopId } = useParams()
  const { data } = useShop(shopId);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const [orderSelectValue] = useState()
  const [showFilter, setShowFilter] = useState(1)



  const { data: productsDiscountData, isFetching: productsIsFetching } = useProducts({
    discount: "yes",
    shop_id: shopId,
    paginate: 30
  });


  const params = {
    paginate: 30,
    page: searchParams.get("page") ?? 1,
    shop_id: shopId
  }

  if(searchParams.get("order")) params["order"] = searchParams.get("order");
  if(searchParams.get("category_id")) params["category_id"] = searchParams.get("category_id");
  if(searchParams.get("direction")) params["direction"] = searchParams.get("direction");
  if(searchParams.get("shop_type")) params["shop_type"] = searchParams.get("shop_type");
  if(searchParams.get('car_make_id')) params["car_make_id"] = searchParams.get('car_make_id');
  if(searchParams.get('car_model_id') ?? 0) params["car_model_id"] = searchParams.get('car_model_id');
  if(searchParams.get('car_generation_id')) params["car_generation_id"] = searchParams.get('car_generation_id');
  if(searchParams.get('height_id')) params["height_id"] = searchParams.get('height_id');
  if(searchParams.get('diameter_id')) params["diameter_id"] = searchParams.get('diameter_id');
  if(searchParams.get('width_id')) params["width_id"] = searchParams.get('width_id');


  const {
    data: dataProducts,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ['products_all', params],
    ({ pageParam = 1 }) => getProducts({ ...params, page: pageParam, random_paginate: 1 }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1
        return lastPage?.data?.length !== 0 ? nextPage : undefined
      },
      onError: (error) => {
        console.log(error);
        console.log("erroraziz");
        if(error.response?.status === 404) {
          navigate('/not-found');
        }
      }
    }
  )

  const image_url = 'https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/storage/' + data?.data?.logo;
  const image_url_cover = 'https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/storage/' + data?.data?.cover;
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const website = data?.data?.shop_information[0]?.website;
  const workTime = data?.data?.shop_information[0]?.work_time;

  const handleLinkClick = (url) => {
    window.open(url, '_blank');
  };


  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOrder = (e) => {
    const queryParams = Object.fromEntries(searchParams.entries());
    switch(e.value) {
      case 1:
        queryParams.order = "id";
        queryParams.direction = "desc";
        break;
      case 2:
        queryParams.order = "price";
        queryParams.direction = "desc";
        break;
      case 3:
        queryParams.order = "price";
        queryParams.direction = "asc";
        break;
      case 4:
        delete queryParams.order;
        delete queryParams.direction;
        queryParams.shop_type = 2;
        break;
      default:
        queryParams.order = "id";
        queryParams.direction = "desc";
        break;
    }


    const updatedSearchParams = new URLSearchParams(queryParams);
    navigate(`${location.pathname}?${updatedSearchParams.toString()}`);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProducts(); // API isteği burada yapılır

        if(response.status === 404) {
          navigate('/not-found');
        }
        // Diğer durumlar için de uygun işlemler burada yapılabilir
      } catch(error) {
        if(error.response?.status === 404) {
          navigate('/not-found');
        }
      }
    };

    fetchData();
  }, [location.search, navigate]);
  const handleShowFilter = (id) => {
    setShowFilter(id)
    if(id === 1) {
      navigate(`${location.pathname}?shop_type=${2}&shop_id=${shopId}`);
    }
  }

  let filterContent
  if(data?.data?.tire_sale_type === 1 || data?.data?.tire_sale_type === 3) {
    filterContent = <Filter params={{ shop_id: shopId }} />
  } else if(data?.data?.tire_sale_type === 2 || data?.data?.tire_sale_type === 3) {
    filterContent = <WheelFilter />
  }


  let filterContentMobil
  if(data?.data?.tire_sale_type === 1 || data?.data?.tire_sale_type === 3) {
    filterContentMobil = <Mobilfilter params={{ shop_id: shopId }} />
  } else if(data?.data?.tire_sale_type === 2 || data?.data?.tire_sale_type === 3) {
    filterContentMobil = <MobilWheelfilter />
  }

  const page = {
    pages: [
      {

        link: "/shops",
        content: "Mağazalar",
      },

    ],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const img_url = "https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/storage/";

  const title = ` Mağazalar - ${data?.data?.name} | avtopro.az `
  const keywords = "magazalar, aksesuar magazası, zapcast magazası, avtomobil, nəqliyyat, nəqliyyat vasitəsi, avtopro, aksesuar, təkərlər, əyləc bəndi, yağlar"
  const description = `${(data?.data?.name ? data?.data?.name : "") + ' - nəqliyyat vasitələrinə aid hər növ ehtiyyat hissələri və aksesuarların satışı| avtopro.az'}`;
  const image = `${data?.data?.logo ? img_url + data?.data?.logo : ''}`

  const siteURL = "https://avtopro.az"
  const { pathname } = useLocation();
  const pageLink = pathname;

  const shopAddress = data?.data?.shop_information[0]?.address;
  const shopPhone = data?.data?.shop_information[0]?.phone1;
  const phoneClear = shopPhone;
  let reversedString = "";
  let resultPhone = "";

  if(phoneClear === "" || phoneClear === null || phoneClear === undefined) {
  } else {
    reversedString = phoneClear.replace(/\s|\(|\)/g, '').split('').reverse().slice(0, 9).reverse().join('');
    resultPhone = "+994" + reversedString;
  }
  const currentUrl = window.location.href;

  // useEffect(() => {

  //   // console.log(dataProducts?.pages[0].data?.data.length);
  //   // console.log("dataProductsaziz");
  //   // if(dataProducts?.pages[0].data?.data.length == 0) {
  //   //   navigate('/not-found');
  //   // }
  //   // Veri yüklemesi tamamen bittiğinde ve data undefined veya 404 ise yönlendirme yap
  //   if(dataProducts?.pages[0].data?.data.length == 0 && !isLoading && !isFetching) {
  //     navigate('/not-found');

  //   }
  // }, [isLoading, isFetching, dataProducts, dataProducts, navigate]);

  // if(isLoading || isFetching) {
  //   return <div>Loading...</div>;
  // }



  useEffect(() => {

    if(!isLoading && !isFetching) {
      const data = dataProducts?.pages?.[0]?.data?.data || [];
      const data_total = dataProducts?.pages?.[0]?.data?.total || [];

      if(Array.isArray(data) && data_total == "") {
        // navigate('/tezlikle');
      }
    }
  }, [isLoading, isFetching, dataProducts, navigate]);

  // useEffect(() => {
  //   // Yükleme tamamlandığında ve veriyi kontrol et
  //   if(!isLoading && !isFetching) {
  //     // dataProducts tam olarak yüklenmişse, kontrol yap
  //     const data = dataProducts?.pages?.[0]?.data?.data || [];

  //     if(Array.isArray(data) && data.length === 0) {
  //       navigate('/not-found');
  //     }
  //   }
  // }, [isLoading, isFetching, dataProducts, navigate]);




  // Verinin yüklenmesini bekleyin ve yüklenirken bir yükleme mesajı gösterin
  // if(isLoading || isFetching) {
  //   return <div>Loading...</div>;
  // }


  // const postalSchema = {
  //   "@context": "https://schema.org",
  //   "@type": "Organization",
  //   "name": `${data?.data?.name}`,
  //   "url": `${siteURL + pageLink}`,
  //   "logo": `${image}`,
  //   "description": `${data?.data?.name} - mağazada məhsulların satışı avtopro.az'da"`,
  //   "address": {
  //     "@type": "PostalAddress",
  //     "streetAddress": `${shopAddress}`,
  //     "addressLocality": "-",
  //     "addressRegion": "-",
  //     "postalCode": "-",
  //     "addressCountry": "Azerbaijan"
  //   },
  //   "contactPoint": {
  //     "@type": "ContactPoint",
  //     "telephone": `${resultPhone}`,
  //     "contactType": "customer service"
  //   }

  // };

  // console.log(shopId);
  // console.log(dataProducts);
  return (
    <>

      <Meta title={title} keywords={keywords} description={description} image={image} data={data} shopAddress={shopAddress} currentUrl={currentUrl} siteURL={siteURL} pageLink={pageLink} shopLogo={image} resultPhone={resultPhone} isShopinnerPage={true} />

      {/* <script type="application/ld+json">
        {JSON.stringify(postalSchema)}
      </script> */}
      <Menu />
      <Breadcrumb page={page} />
      <section className={classes.shopImgCover}>
        {/* <img src={image_url} alt={data?.data?.name} />   */}
        <img src={image_url_cover} alt={data?.data?.name} />
      </section>


      <div className={`${classes.description_content} container showDesktopFilter`}>
        <h3 className={classes.shop_name_hd}>{data?.data?.name}</h3>

        {data?.data?.information ? (
          <>
            <div
              className={`${classes.desc_info} ${isExpanded ? classes.expanded : ''}`}
              dangerouslySetInnerHTML={{ __html: data?.data?.information ? `${data?.data?.information}` : "" }}
            ></div>
            <div className={`${classes.more_text} ${isExpanded ? classes.clicked : ''}`}>
              <span onClick={handleToggle} >Daha çox</span>
            </div>
          </>
        ) : null}

      </div>


      <div className={`${classes.description_content} ${classes.shop_detail_mob} container showMobilFilter shop_detail_mob`}>

        <div className={classes.shopImgMob}>
          <img src={image_url} alt={data?.data?.name} />
        </div>
        <div className={classes.shop_right_mob}>
          <h3 className={classes.shop_name_hd}>{data?.data?.name}</h3>
          <p><Link  > {data?.data?.shop_information[0]?.address}</Link></p>
          <p><Link to={`tel:+994${data?.data?.shop_information[0]?.phone1?.replace(/^0/, '') || ''}`}
          > {data?.data?.shop_information[0]?.phone1}</Link></p>
          <p><b>{data?.data?.products_count} elan</b> </p>
          <p><BsEyeFill fill="#757575" /> {data?.data?.views}</p>
        </div>
      </div>
      <div className={`container ${classes.mobil__shop__info}`}>
        <p onClick={openModal}><BsInfoCircle fill="#68a834" /> Haqqında </p>
      </div>

      {data?.data?.tire_sale_type === 3 ? (<div className={`${classes.showFilterBtns} showMobilFilter`}>

        <button onClick={() => handleShowFilter(1)} className={showFilter === 1 ? "activeA" : ""}>Ehtiyyat hissəsi</button>
        <button onClick={() => handleShowFilter(2)} className={showFilter === 2 ? "activeA" : ""}>Disklər və təkərlər </button>

      </div>) : ""}

      {data?.data?.tire_sale_type !== 3 && filterContentMobil}
      {data?.data?.tire_sale_type === 3 && showFilter === 1 ? <Mobilfilter params={{ shop_id: shopId }} /> : ""}
      {data?.data?.tire_sale_type === 3 && showFilter === 2 ? <MobilWheelfilter /> : ""}


      <Shopinfo data={data} handleLinkClick={handleLinkClick} workTime={workTime} website={website} />

      {data?.data?.tire_sale_type === 3 ? (<div className={`${classes.showFilterBtns} showDesktopFilter`}>

        <button onClick={() => handleShowFilter(1)} className={showFilter === 1 ? "activeA" : ""}>Ehtiyyat hissəsi</button>
        <button onClick={() => handleShowFilter(2)} className={showFilter === 2 ? "activeA" : ""}>Disklər və təkərlər </button>

      </div>) : ""}

      {data?.data?.tire_sale_type !== 3 && filterContent}

      {data?.data?.tire_sale_type === 3 && showFilter === 1 ? <Filter params={{ shop_id: shopId }} /> : ""}
      {data?.data?.tire_sale_type === 3 && showFilter === 2 ? <WheelFilter /> : ""}
      <ShopProduct
        productsDiscount={productsDiscountData}
        productsIsFetching={productsIsFetching}
        title="Endirimli məhsullar"
        discount={true}
      />

      <ParentCategory shopId={shopId} />

      <Product
        end="end"
        orderSelectValue={orderSelectValue}
        handleOrder={handleOrder}

        shopId={shopId}
        data={dataProducts}
        isLoading={isLoading}
        isFetching={isFetching}
        error
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />



      <Modal isOpen={isModalOpen} onClose={closeModal} >
        <div className='shop__info__modal'>
          <div className='shop__info__item'>
            <button><TbClockHour4 /></button>
            <div className='right__info'>
              <span>İş saatları</span>

              <p>{workTime ? workTime : "------"}</p>
            </div>
          </div>
          <div className='shop__info__item'>
            <button><TfiWorld /> </button>
            <div className='right__info'>
              <span>Web səhifə</span>
              <p><Link onClick={() => handleLinkClick(`https://${website}`)} > {website ? website : "--------"}</Link></p>
            </div>
          </div>
          <div className='shop__info__item'>
            <button><BsFillTelephoneFill /> </button>
            <div className='right__info'>
              <span>Əlaqə nömrəsi</span>
              <p><Link to={`tel:+994${data?.data?.shop_information[0]?.phone1?.replace(/^0/, '') || ''}`}
              > {data?.data?.shop_information[0]?.phone1}</Link></p>
            </div>
          </div>
          <div className='shop__info__item'>
            <button><HiLocationMarker /> </button>
            <div className='right__info'>
              <span>Ünvan</span>
              <p><Link  > {data?.data?.shop_information[0]?.address}</Link></p>
            </div>
          </div>
          <div className='shop__text'>
            <Link className='shop__subscribe'> <GrYoutube color="#FE0000" fontSize="2.2rem" /> Abunə ol</Link>
            {data?.data?.information && <h3>Mağaza haqqında</h3>}
            <p dangerouslySetInnerHTML={{ __html: data?.data?.information ? `${data?.data?.information}` : "" }}></p>
          </div>

        </div>
      </Modal>

    </>
  )
}


export default Shopinner


