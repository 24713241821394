import React, { memo } from "react";
import { GrYoutube } from "react-icons/gr";
import { Link } from "react-router-dom";
import classes from "../../../assets/styles/shopInfo.module.css";
import { IoLogoFacebook } from "react-icons/io";
import { FaInstagramSquare, FaWhatsappSquare } from "react-icons/fa";
import { BsTelephoneFill } from "react-icons/bs";
import { TiLocation } from "react-icons/ti";
import { TbWorld } from "react-icons/tb";
import { Checked } from "../../../assets/icons/Icons";
import { workTimeIcon } from "../../../assets/icons/Icons";

const DealerInfo = ({ data, handleLinkClick, website, workTime }) => {
  // console.log(data?.data?.shop_information[0]);
  // const phone1_link = data?.data?.shop_information[0]?.phone1 || '';

  const searchTerm = "Avtoproda rəsmi diler";
  const encodedSearchTerm = encodeURIComponent(searchTerm);
  const phoneClear = data?.data?.shop_information[0]?.phone1;
  let reversedString = "";
  let resultPhone = "";

  if (phoneClear == "" || phoneClear == null || phoneClear == undefined) {
  } else {
    reversedString = phoneClear
      .replace(/\s|\(|\)/g, "")
      .split("")
      .reverse()
      .slice(0, 9)
      .reverse()
      .join("");
    resultPhone = "994" + reversedString;
  }

  const urlWp = `https://wa.me/${resultPhone}/?text=${encodedSearchTerm}`;

  return (
    <section className={`container ${classes.shop__container}`}>
      <h3>
        {data?.data?.name} {Checked}
      </h3>
      <div className={classes.shop__info}>
        <div className={classes.item}>
          <div className={classes.item__left}>
            <Link className={classes.subscribe} to="/video">
              {" "}
              <GrYoutube color="#FE0000" fontSize="2.2rem" /> Youtube
            </Link>
            <div className={classes.icons}>
              <Link
                to={`${
                  data?.data?.shop_information[0]?.facebook ??
                  "https://www.facebook.com"
                }`}
                target="_blank"
              >
                {" "}
                <IoLogoFacebook />
              </Link>
              <Link
                to={`${
                  data?.data?.shop_information[0]?.instagram ??
                  "https://www.instagram.com/"
                }`}
                target="_blank"
              >
                {" "}
                <FaInstagramSquare />
              </Link>
              <Link
                to={`https://api.whatsapp.com/send?phone=${data?.data?.shop_information[0]?.phone1?.replace(
                  /\D/g,
                  ""
                )}`}
                target="_blank"
              >
                {" "}
                <FaWhatsappSquare />
              </Link>
            </div>
          </div>
          <div className={`${classes.item__right} `}>
            <p>
              <Link to={urlWp} target="_blank">
                {" "}
                <BsTelephoneFill className="telphoneSvg" />
                {data?.data?.shop_information[0]?.phone1}
              </Link>
              <div>{data?.data?.shop_information[0]?.phone2}</div>
              {/* <Link to={`tel:+994${data?.data?.shop_information[0]?.phone2}`}>
                {data?.data?.shop_information[0]?.phone2}
              </Link> */}
            </p>
            {data?.data?.shop_information[0]?.phone2 && (
              <p>
                <div>
                  <BsTelephoneFill className="telphoneSvg" />{" "}
                  {data?.data?.shop_information[0]?.phone2}
                </div>
              </p>
            )}
            <p>
              <Link
                to={`https://www.google.com/maps?q=${encodeURIComponent(
                  data?.data?.shop_information[0]?.address
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TiLocation />
                {data?.data?.shop_information[0]?.address}
              </Link>
            </p>
          </div>
        </div>
        <div className={`${classes.lineBorder}`}></div>
        <div className={`${classes.item} ${classes.item2}`}>
          <div className={classes.item__left}>
            <p>
              <Link onClick={() => handleLinkClick(`https://${website}`)}>
                <TbWorld /> {website ? website : "-----"}
              </Link>
            </p>
            <p>
              <Link>
                {workTimeIcon} {workTime ? workTime : "-----"}
              </Link>
            </p>
          </div>
          {/* <div className={classes.item__right}>
            <Link className={classes.service}>Servis xidməti <BsArrowRight /></Link>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default memo(DealerInfo);
